import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { connect } from "react-redux"
import { ChatSelector } from "../util"
import { TrackLoaderContainer } from "./loader"
import TrackResult from "./result"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const GACategory = "Shipment Details"

export const Track = ({ shipment, match, list, shipmentId, history, legs }) => {
    const { logGAEvent } = useGAContext()
    useEffect(() => {
        logGAEvent(GACategory, "Page Mount")
    }, [])
    return (
        <Fragment>
            <ChatSelector shipmentId={shipmentId} />
            <TrackLoaderContainer match={match} shipment={shipment} />
            <TrackResult
                shipmentLegs={legs}
                list={list}
                shipmentId={shipmentId}
                history={history}
                gaCategory={GACategory}
            />
        </Fragment>
    )
}

Track.propTypes = {
    match: PropTypes.object,
    shipment: PropTypes.object,
}

Track.defaultProps = {
    match: { params: {} },
    shipment: undefined,
}

const mapStateToProps = (state, props) => {
    const shipmentId = get(props, "computedMatch.params.shipmentId")
    const { shipment, legs } = get(state, `shipment.list[${shipmentId}]`, {})
    const history = props?.history

    return {
        match: props.computedMatch,
        shipment,
        shipmentId,
        legs,
        list: Object.keys(state.accessorials.list).reduce(
            (prev, key) => [...prev, ...state.accessorials.list[key]],
            []
        ),
        history,
    }
}

export const TrackContainer = connect(mapStateToProps)(Track)
