import React, { useState } from "react"
import GlobalSpinner from "../common/GlobalSpinner"
import DashboardShipmentItem from "./DashboardShipmentItem"
import DashboardQuoteItem from "./DashboardQuoteItem"
import { withStyles } from "@material-ui/core"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    dashboardTable: {
        width: "100%",
    },
})

const DashboardItemPresentation = ({
    shipments,
    isLoaded,
    isFetching,
    onShipAgain,
    onClickDetails,
    carriers,
    onViewQuote,
    onCancelShipment,
    dismissAlert,
    fetchElements,
    fetchElementsOnFilterApply,
    page,
    pageSize,
    dashboardFilters,
    activeDashboardTile,
    gaCategory,
}) => {
    const { logGAEvent } = useGAContext()
    const [openItemId, setOpenItemId] = useState(null)
    const [openItem, setOpenItem] = useState(false)
    const classes = useStyles()

    const toggleInfo = (id, type) => {
        if (openItem === true && openItemId !== id) {
            setOpenItemId(id)
            logGAEvent(gaCategory, "Item Open", type)
        } else {
            setOpenItemId(id)
            setOpenItem(!openItem)
            if (openItem) {
                logGAEvent(gaCategory, "Item Close", type)
            } else {
                logGAEvent(gaCategory, "Item Open", type)
            }
        }
    }

    const noExpand = event => {
        event.stopPropagation()
    }

    return (
        <>
            {isFetching && <GlobalSpinner />}
            {isLoaded && (
                <div id="dashboard-table" className={classes.dashboardTable}>
                    {!!carriers &&
                        shipments.map((shipment, index) =>
                            shipment.shipment ? (
                                <DashboardShipmentItem
                                    index={index}
                                    shipmentId={
                                        shipment.identifiers
                                            .internalTrackingNumber
                                    }
                                    fetchElements={fetchElements}
                                    fetchElementsOnFilterApply={
                                        fetchElementsOnFilterApply
                                    }
                                    onViewQuote={onViewQuote}
                                    onClickDetails={() =>
                                        onClickDetails(shipment)
                                    }
                                    key={
                                        shipment.identifiers
                                            .internalTrackingNumber
                                    }
                                    {...shipment}
                                    toggleInfo={id =>
                                        toggleInfo(id, "Shipment")
                                    }
                                    noExpand={noExpand}
                                    isOpen={
                                        shipment.shipment._id === openItemId &&
                                        openItem
                                    }
                                    onShipAgain={onShipAgain}
                                    onCancelShipment={onCancelShipment}
                                    carrierCapabilities={
                                        carriers[shipment.shipment.carrier]
                                    }
                                    dismissAlert={dismissAlert}
                                    shipmentCPG={shipment.query.cpg}
                                    page={page}
                                    pageSize={pageSize}
                                    dashboardFilters={dashboardFilters}
                                    activeDashboardTile={activeDashboardTile}
                                    associatedOrder={
                                        shipment.query?.associatedOrder
                                    }
                                />
                            ) : (
                                <DashboardQuoteItem
                                    index={index}
                                    onViewQuote={onViewQuote}
                                    onClickDetails={() =>
                                        onClickDetails(shipment)
                                    }
                                    key={shipment._id}
                                    toggleInfo={id => toggleInfo(id, "Quote")}
                                    {...shipment}
                                    isOpen={
                                        shipment._id === openItemId && openItem
                                    }
                                />
                            )
                        )}
                </div>
            )}
        </>
    )
}

export default DashboardItemPresentation
