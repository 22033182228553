import React, { useState, useEffect } from "react"
import { get, values, orderBy } from "lodash"
import ReactGA from "react-ga4"
import createCachedSelector from "re-reselect"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { change } from "redux-form"
import {
    Button,
    Tooltip,
    Grid,
    Typography,
    CircularProgress,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { IntercomAPI } from "react-intercom"
import GlobalSpinner from "../../common/GlobalSpinner"
import {
    changePath,
    setTableFilterToShipments,
    toggleTableFilter,
} from "../../../actions"
import { dismissAlert } from "../../../actions/alerts"
import {
    requestQuotes,
    copyShipment,
    requestQuotesWithFilters,
    exportQuotesAsCsv,
} from "../../../actions/quote-request"
import { cancelShipment, selectShipment } from "../../../actions/book-shipment"
import {
    requestShipments,
    exportShipmentsAsCsv,
    requestShipmentsWithFilters,
} from "../../../actions/track"
import {
    applyActiveDashboardTile,
    applyDashboardFilters,
} from "../../../actions/dashboard"
import { changePageSize, changePage } from "../../../actions/pagination"
import { trackGAEvent } from "../../../actions/user"
import DashboardItemPresentation from "../DashboardItemPresentation"
import DashboardTiles from "../tiles"
import DashboardFilters from "./dashboardFilters"
import TablePagination from "../../common/pagination/TablePagination"
import { useAccessorialsContext } from "../../../context/providers/AccessorialsProvider"

const GACategory = "Dashboard"

const styles = theme => ({
    dashboard__itemContainer: {
        padding: "0% 3%",
    },
    export_btn: {
        marginTop: "-36px",
    },
    progress: {
        margin: theme.spacing(2),
        top: "50%",
        right: "50%",
        position: "absolute",
        zIndex: 9999,
    },
    btnrow: {
        marginLeft: theme.spacing(5),
    },
    export__error: {
        marginLeft: "10px",
    },
    submitLoader: {
        marginLeft: "10px",
    },
    export__csv__button: {
        marginLeft: "80px",
        marginBottom: "15px",
        width: "max-content",
    },
    dashboard__filterChip: {
        marginRight: "10px",
    },
    dashboard__filtersContainer: {
        paddingBottom: "16px",
        paddingTop: "16px",
    },
})

const DashboardTable = props => {
    const {
        shipments,
        onClickDetails,
        isLoaded,
        carriers,
        totalElements,
        isQuoteCopying,
        isFetching,
        savePageSize,
        classes,
        dashboardFilterFormValues,
        onViewQuote,
        tableFilters,
        onCancelShipment,
        dismissAlert,
        locations,
        dashboardFilters = {},
        resetFormField,
        setToShipmentsOnly,
        activeDashboardTile,
        setActiveTileInStore,
        savePage,
        trackGA,
    } = props

    const { getAccessorialsList } = useAccessorialsContext()
    const [exportingShipmentCSV, setExportingShipmentCSV] = useState(false)
    const [exportingQuotesCSV, setExportingQuotesCSV] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(props.pageSize)
    const [exportError, setExportError] = useState("")

    useEffect(() => {
        IntercomAPI("trackEvent", "dashboard")
        getAccessorialsList("en-us")
    }, [])

    const setIsLoading = value => {
        setLoadingTable(value)
    }

    const onShipAgain = async ({ shipmentId, queryVersion, openSnackbar }) => {
        setShowSpinner(true)
        await props.onShipAgain({
            shipmentId,
            queryVersion,
            openSnackbar,
        })
        setShowSpinner(false)
    }

    const onShipmentExportClick = async () => {
        setExportingShipmentCSV(true)
        trackGA(GACategory, "Export As CSV Click")
        try {
            await props.onExportClick()
            setExportingShipmentCSV(false)
        } catch (err) {
            setExportError(err?.response?.message ?? "Error exporting CSV")
            trackGA(GACategory, "Error exporting CSV")
            setExportingShipmentCSV(false)
        }
    }
    const onQuotesExportClick = async () => {
        setExportingQuotesCSV(true)
        trackGA(GACategory, "Export As CSV Click")
        try {
            await props.onExportQtuotesClick()
            setExportingQuotesCSV(false)
        } catch (err) {
            setExportError(err?.response?.message ?? "Error exporting CSV")
            trackGA(GACategory, "Error exporting CSV")
            setExportingQuotesCSV(false)
        }
    }

    const fetchElements = async targetSize => {
        setLoadingTable(true)
        try {
            await props.fetchElements(targetSize)
            setLoadingTable(false)
        } catch (_) {
            setLoadingTable(false)
        }
    }

    const applyDashboardFilters = () => {
        props.applyDashboardFilters()
        setPage(1)
        setPageSize(10)
    }

    const applyChangeFilter = filter => {
        const {
            handleChangeFilter,
            setActiveTileInStore,
            activeDashboardTile,
        } = props

        handleChangeFilter(filter)
        setPage(1)
        if (filter === "quotes" && !!activeDashboardTile) {
            setActiveTileInStore(null)
            props.fetchElementsOnFilterApply()
        }
    }

    const openFilterDrawer = () => {
        setFilterDrawerOpen(true)
        trackGA(GACategory, "Filter Drawer Open")
    }

    const closeFilterDrawer = () => {
        setFilterDrawerOpen(false)
        trackGA(GACategory, "Filter Drawer Close")
    }

    const adjustPagination = (page, pageSize) => {
        setPage(page)
        setPageSize(pageSize)
        trackGA(GACategory, `Table Page ${page}, Page Size ${pageSize}`)
    }

    const adjustPage = page => {
        setPage(page)
    }

    return showSpinner ? (
        <GlobalSpinner />
    ) : (
        <div className={classes.dashboard__itemContainer}>
            <DashboardTiles
                fetchElementsOnFilterApply={props.fetchElementsOnFilterApply}
                setToShipmentsOnly={setToShipmentsOnly}
                setIsLoading={setIsLoading}
                adjustPage={adjustPage}
                setActiveTileInStore={setActiveTileInStore}
            />
            <DashboardFilters
                tableFilters={tableFilters}
                locations={locations}
                handleChangeFilter={applyChangeFilter}
                classes={classes}
                applyDashboardFilters={applyDashboardFilters}
                dashboardFilters={dashboardFilters}
                filterDrawerOpen={filterDrawerOpen}
                openFilterDrawer={openFilterDrawer}
                closeFilterDrawer={closeFilterDrawer}
                resetFormField={resetFormField}
                carriers={carriers}
                formValues={dashboardFilterFormValues}
                fetchElementsOnFilterApply={props.fetchElementsOnFilterApply}
                trackGA={trackGA}
                gaCategory={GACategory}
            />
            <TablePagination
                elements={shipments}
                fetchElements={fetchElements}
                savePageSize={savePageSize}
                savePage={savePage}
                pageSize={pageSize}
                page={page}
                loadingTable={loadingTable}
                totalElements={totalElements}
                adjustPagination={adjustPagination}
                render={elementsToDisplay => (
                    <DashboardItemPresentation
                        carriers={carriers}
                        shipments={elementsToDisplay}
                        onClickDetails={onClickDetails}
                        onViewQuote={onViewQuote}
                        onShipAgain={onShipAgain}
                        isLoaded={isLoaded}
                        isFetching={isFetching}
                        isQuoteCopying={isQuoteCopying}
                        classes={classes}
                        onCancelShipment={onCancelShipment}
                        dismissAlert={dismissAlert}
                        fetchElements={props.fetchElements}
                        fetchElementsOnFilterApply={
                            props.fetchElementsOnFilterApply
                        }
                        page={page}
                        pageSize={pageSize}
                        dashboardFilters={dashboardFilters}
                        activeDashboardTile={activeDashboardTile}
                        gaCategory={GACategory}
                    />
                )}
                noElement={() => (
                    <Grid item container justify="center">
                        {loadingTable && <GlobalSpinner />}
                        {activeDashboardTile ||
                        Object.keys(dashboardFilters?.carrierFilter ?? {})
                            .length ||
                        Object.keys(dashboardFilters?.locationFilter ?? {})
                            .length ? (
                            <Grid item container justify="center">
                                <Typography variant="subtitle1">
                                    <FormattedMessage
                                        id="dashboard__noElementsFromFilter"
                                        defaultMessage="There are no shipments matching your current filter."
                                    />
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item container>
                                <Grid item container justify="center">
                                    <Typography variant="h6">
                                        <FormattedMessage
                                            id="dashboard__noElements"
                                            defaultMessage="Welcome to LTL Select! You have no quotes/shipments to display"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container justify="center">
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="dashboard__noElementsDetail"
                                            defaultMessage="Quotes and shipments that you create will show up on this dashboard"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )}
            />
            {isLoaded && !loadingTable && shipments.length > 0 && (
                <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.export_btn}
                >
                    <Grid
                        item
                        container
                        className={classes.export__csv__button}
                    >
                        <Tooltip
                            title={
                                <FormattedMessage
                                    id="dashboard__lastNDays"
                                    defaultMessage="Last {n} days"
                                    values={{ n: 90 }}
                                />
                            }
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={onShipmentExportClick}
                            >
                                <FormattedMessage
                                    id="dashboard__exportShipmentsAsCsv"
                                    defaultMessage="Export Shipments as CSV"
                                />
                            </Button>
                        </Tooltip>
                        {exportingShipmentCSV && (
                            <CircularProgress
                                className={classes.submitLoader}
                                size={24}
                                color="secondary"
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.export__csv__button}
                    >
                        <Tooltip
                            title={
                                <FormattedMessage
                                    id="dashboard__lastNDays"
                                    defaultMessage="Last {n} days"
                                    values={{ n: 90 }}
                                />
                            }
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={onQuotesExportClick}
                            >
                                <FormattedMessage
                                    id="dashboard__exportQuotesAsCsv"
                                    defaultMessage="Export Quotes as CSV"
                                />
                            </Button>
                        </Tooltip>
                        {exportingQuotesCSV && (
                            <CircularProgress
                                className={classes.submitLoader}
                                size={24}
                                color="secondary"
                            />
                        )}
                    </Grid>
                    {exportError && (
                        <Grid item>
                            <Typography
                                color="secondary"
                                variant="caption"
                                className={classes.export__error}
                            >
                                {exportError}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    )
}

DashboardItemPresentation.propTypes = {
    shipments: PropTypes.array.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isQuoteCopying: PropTypes.bool.isRequired,
    onClickDetails: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    shipmentList: PropTypes.array.isRequired,
    totalElements: PropTypes.number.isRequired,
}

DashboardTable.propTypes = {
    ...DashboardItemPresentation.PropTypes,
    fetchElements: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    savePageSize: PropTypes.func.isRequired,
    onExportClick: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
}

const keySelector = state =>
    [
        get(state, "dashboard.filters.quotes") && "quotes",
        get(state, "dashboard.filters.shipments") && "shipments",
    ].join(",")

const tableSelector = createCachedSelector(
    state => state.dashboard.filters,
    state => state.quotes.list.items,
    state => state.shipment.list,
    (filters, quotesList, shipmentsList) => {
        const { quotes, shipments } = filters || {}
        const list = [
            ...(quotes ? values(quotesList || {}) : []),
            ...(shipments ? values(shipmentsList || {}) : []),
        ]
        return orderBy(list, ["created_at"], ["desc"])
    }
)(keySelector)

const mapStateToProps = state => {
    const { quotes, shipments } = get(state, "dashboard.filters", {})
    const totalElements =
        (shipments ? state.shipment.totalCount : 0) +
        (quotes ? state.quotes.list.totalCount : 0)
    const locations = get(state, "user.profile.locations", []).filter(
        item =>
            !get(item, "users[0].permissions.suspended.value") &&
            get(item, "users[0].access.granted")
    )

    return {
        isLoaded:
            (!shipments || state.shipment.isLoaded) &&
            (!quotes || state.quotes.list.isLoaded),
        isFetching:
            (shipments && state.shipment.isFetching) ||
            (quotes && state.quotes.list.isFetching),
        isQuoteCopying: state.quotes.active.isFetching,
        pageSize: state.shipment.pagination.pageSize,
        carriers: state.carriers.carriers,
        tableFilters: state.dashboard.filters,
        totalElements,
        shipments: tableSelector(state),
        locations,
        dashboardFilters: get(state, "dashboard.dashboardFilters", {}),
        dashboardFilterFormValues: get(
            state,
            "form.dashboardFilters.values",
            {}
        ),
        activeDashboardTile: state?.dashboard?.activeDashboardTile,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    setActiveTileInStore: value => dispatch(applyActiveDashboardTile(value)),
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
    onClickDetails: record => {
        dispatch(trackGAEvent("Shipment", "Tracking details"))
        return dispatch(selectShipment(record.identifiers))
    },
    fetchElements: async (targetSize, force) => {
        return Promise.all([
            dispatch(requestShipments(targetSize, force)),
            dispatch(requestQuotes(targetSize, force)),
        ])
    },
    fetchElementsOnFilterApply: async targetSize => {
        return await Promise.all([
            dispatch(requestQuotesWithFilters(targetSize)),
            dispatch(requestShipmentsWithFilters(targetSize)),
        ])
    },
    savePageSize: pageSize => dispatch(changePageSize(pageSize)),
    savePage: page => dispatch(changePage(page)),
    onExportClick: () => dispatch(exportShipmentsAsCsv()),
    onExportQtuotesClick: () => dispatch(exportQuotesAsCsv()),
    onViewQuote: (bolNumber, isQuickQuote, isQuickRate, queryVersion) => {
        if (queryVersion === "V1" || !queryVersion) {
            dispatch(
                changePath(
                    isQuickQuote ? `/qrate/${bolNumber}` : `/rate/${bolNumber}`
                )
            )
        } else if (queryVersion === "V2") {
            dispatch(
                changePath(
                    isQuickRate
                        ? `/quickRate/${bolNumber}`
                        : `/book/${bolNumber}`
                )
            )
        }
    },
    applyDashboardFilters: () => dispatch(applyDashboardFilters()),
    onShipAgain: async ({ shipmentId, queryVersion, openSnackbar }) => {
        await dispatch(
            copyShipment({
                shipmentId,
                queryVersion,
                shipAgain: true,
                openSnackbar,
            })
        )
    },
    onCancelShipment: (...args) => dispatch(cancelShipment(...args)),
    handleChangeFilter: filter => dispatch(toggleTableFilter(filter)),
    setToShipmentsOnly: () => dispatch(setTableFilterToShipments()),
    dismissAlert: (bolNumber, alertId) =>
        dispatch(dismissAlert(bolNumber, alertId)),
    resetFormField: (field, value) =>
        dispatch(change("dashboardFilters", field, value)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(DashboardTable)
)
