import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import {
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Paper,
} from "@material-ui/core"
import { Formik, Field, Form } from "formik"
import {
    requestCarrierMetadata,
    requestCarrierData,
    updateCarrier,
    deleteCarrier,
    requestCurrentCarriers,
} from "./requests"
import FormikTextField from "./form/formikTextField"
import FormikSwitch from "./form/formikSwitch"
import Tutorial from "./Tutorial"
import {
    useHistory,
    useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { Skeleton } from "@material-ui/lab"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { goFetch } from "../../http"
import GlobalSpinner from "../common/GlobalSpinner"

const CarrierForm = ({ onboardingLocation, refetchUser }) => {
    const [carrierFields, setCarrierFields] = useState([])
    const [carrierFieldValues, setCarrierFieldValues] = useState(null)
    const [carrierDetails, setCarrierDetails] = useState(null)
    const [currentAccounts, setCurrentAccounts] = useState([])
    const [working, setWorking] = useState()
    const [removed, setRemoved] = useState(false)
    const [addSuccess, setAddSuccess] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const history = useHistory()
    const { carrierId, mode } = useParams()
    const { openSnackbar } = useSnackbarContext()
    const locationName =
        onboardingLocation?.displayName ??
        onboardingLocation?.billingContact?.companyName

    useEffect(() => {
        const fetchFieldNames = async () => {
            const { accounts } = await requestCurrentCarriers(
                onboardingLocation?.cpgCode
            )
            setCurrentAccounts(accounts)
            await getFieldInfo()
        }
        const fetchFieldValues = async () => {
            await getFieldData(carrierFields)
        }
        if (carrierFields?.length === 0) {
            fetchFieldNames()
        } else {
            fetchFieldValues()
        }
    }, [carrierFields])

    const onCancelClick = () => {
        if (mode === "add") {
            history.push("/carrier/add")
        } else {
            history.push("/locations")
        }
    }

    const getFieldInfo = async () => {
        try {
            const carrierMetadataResponse = await requestCarrierMetadata(
                carrierId
            )
            setCarrierFields(
                carrierMetadataResponse?.accountDefinitions[0]
                    ?.accountFieldDefinitions
            )
            setCarrierDetails(carrierMetadataResponse?.capacityProvider)
            return carrierMetadataResponse?.accountDefinitions[0]
                ?.accountFieldDefinitions
        } catch (error) {}
    }

    const getFieldData = async accountFieldDefinitions => {
        if (mode === "add") {
            const initialValues = {}
            accountFieldDefinitions.forEach(item => {
                if (item.code === "directionCode")
                    initialValues.directionCode = "SHIPPER"
                else if (item.code === "paymentTermsCode")
                    initialValues.paymentTermsCode = "PREPAID"
                else if (item.code === "accountFlag1")
                    initialValues.accountFlag1 = false
                else initialValues[item.code] = ""
            })
            initialValues.accountDefinitionIdentifier = "STANDARD"
            initialValues.billToLocationId =
                onboardingLocation?.billToLocationId
            initialValues.enableDirectionOverride = true
            initialValues.enablePaymentTermsOverride = true
            setCarrierFieldValues(initialValues)
        } else {
            try {
                const accountInfo = currentAccounts.find(
                    acct => acct?.code === carrierId
                )
                const carrierDataResponse = await requestCarrierData(
                    accountInfo?.id
                )
                setCarrierFieldValues(carrierDataResponse?.accounts[0])
            } catch (error) {}
        }
    }

    const removeCarrier = async () => {
        handleDialogClose()
        const accountInfo = currentAccounts.find(
            acct => acct?.code === carrierId
        )
        setWorking(true)
        try {
            if (accountInfo?.id) {
                const cpgCode = onboardingLocation?.cpgCode
                await deleteCarrier(accountInfo?.id, cpgCode)
                const { accounts } = await requestCurrentCarriers(cpgCode)
                setRemoved(
                    !accounts?.map(acct => acct?.code)?.includes(carrierId)
                )
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="carrier.onboarding.delete.success"
                        defaultMessage="{carrierName} has been removed from {location}"
                        values={{
                            carrierName: carrierDetails?.name,
                            location: locationName,
                        }}
                    />
                )
            } else {
                openSnackbar(
                    "error",
                    "Error deleting carrier: account id not found"
                )
            }
        } catch (error) {
            setRemoved(false)
            openSnackbar("error", "Error deleting carrier")
        }
        const updatedOnboardingLocation = onboardingLocation
        updatedOnboardingLocation.carriers = onboardingLocation.carriers?.filter(
            carrier => carrier?.carrier !== carrierId
        )
        await refetchUser()
        setWorking(false)
    }

    const addCarrier = async (location, carrierId, values) => {
        setWorking(true)
        const payload = {
            accounts: [values],
            code: carrierId,
            capacityProviderIdentifier: {
                type: "SCAC",
                value: carrierId,
            },
            group: {
                code: location?.cpgCode,
                id: location?.cpgId,
                name: location?.billingContact?.companyName,
            },
        }
        try {
            await goFetch(
                `/carrier/onboarding/create/capacityProvider?cpgCode=${location?.cpgCode}`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                    data: payload,
                },
                true
            )
            const { accounts } = await requestCurrentCarriers(
                onboardingLocation?.cpgCode
            )
            setAddSuccess(
                accounts?.map(acct => acct?.code)?.includes(carrierId)
            )
            openSnackbar(
                "success",
                <FormattedMessage
                    id="carrier.onboarding.add.success"
                    defaultMessage="{carrierName} has been added to {location}"
                    values={{
                        carrierName: carrierDetails?.name,
                        location: locationName,
                    }}
                />,
                4000
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="carrier.onboarding.add.error"
                    defaultMessage="There was an error adding this carrier."
                />,
                4000
            )
        }
        await refetchUser()
        setWorking(false)
    }

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    const determineFieldComponent = code => {
        switch (code) {
            case "accountFlag1":
                return FormikSwitch
            default:
                return FormikTextField
        }
    }

    const ignoredFields = [
        "enableDirectionOverride",
        "enablePaymentTermsOverride",
        "billToLocationId",
        "paymentTermsCode",
        "directionCode",
    ]

    return (
        <>
            {working ? <GlobalSpinner /> : null}
            {removed || addSuccess ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "300px",
                    }}
                >
                    <Typography variant="body1" color="primary">
                        {addSuccess ? (
                            <FormattedMessage
                                id="carrier.onboarding.add.success"
                                defaultMessage="{carrierName} has been added to {location}"
                                values={{
                                    carrierName: carrierDetails?.name,
                                    location: locationName,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="carrier.onboarding.delete.success"
                                defaultMessage="{carrierName} has been removed from {location}"
                                values={{
                                    carrierName: carrierDetails?.name,
                                    location: locationName,
                                }}
                            />
                        )}
                    </Typography>
                </Box>
            ) : null}
            {!removed && !addSuccess ? (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "15px",
                        }}
                    >
                        <Typography variant="h6" color="primary">
                            {carrierDetails?.name}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginTop: "20px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            flexWrap: "wrap",
                        }}
                    >
                        <Paper
                            style={{
                                width: "30%",
                                padding: "10px",
                                marginBottom: "20px",
                                minWidth: "320px",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {!carrierFieldValues ? (
                                    [0, 1, 2].map(x => (
                                        <Skeleton
                                            key={x}
                                            variant="rect"
                                            width={"100%"}
                                            height={40}
                                            style={{
                                                marginBottom: "10px",
                                            }}
                                        />
                                    ))
                                ) : (
                                    <Formik
                                        initialValues={carrierFieldValues}
                                        enableReinitialize
                                        onSubmit={async (
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            setWorking(false)
                                            if (mode === "edit") {
                                                const accountInfo = currentAccounts.find(
                                                    acct =>
                                                        acct?.code === carrierId
                                                )
                                                try {
                                                    await updateCarrier(
                                                        onboardingLocation,
                                                        carrierId,
                                                        values,
                                                        accountInfo?.id
                                                    )

                                                    openSnackbar(
                                                        "success",
                                                        <FormattedMessage
                                                            id="carrier.onboarding.edit.success"
                                                            defaultMessage="Successfully updated carrier credentials"
                                                        />
                                                    )
                                                } catch (error) {
                                                    openSnackbar(
                                                        "error",
                                                        <FormattedMessage
                                                            id="carrier.onboarding.edit.error"
                                                            defaultMessage="Error editing carrier credentials"
                                                        />
                                                    )
                                                }
                                            } else if (mode === "add") {
                                                await addCarrier(
                                                    onboardingLocation,
                                                    carrierId,
                                                    values
                                                )
                                            }
                                            setWorking(false)
                                        }}
                                    >
                                        {({ ...formikProps }) => (
                                            <Form style={{ width: "100%" }}>
                                                {carrierFields.map(
                                                    (field, index) => {
                                                        if (
                                                            !ignoredFields.includes(
                                                                field.code
                                                            )
                                                        ) {
                                                            return (
                                                                <Field
                                                                    style={{
                                                                        width:
                                                                            "100%",
                                                                    }}
                                                                    name={
                                                                        field.code
                                                                    }
                                                                    label={
                                                                        field.name
                                                                    }
                                                                    key={
                                                                        field.code
                                                                    }
                                                                    type={
                                                                        (field.code ===
                                                                            "credential1" ||
                                                                            field.code ===
                                                                                "credential2" ||
                                                                            field.code ===
                                                                                "credential3") &&
                                                                        "password"
                                                                    }
                                                                    component={determineFieldComponent(
                                                                        field.code
                                                                    )}
                                                                    required={
                                                                        field.constraint ===
                                                                            "REQUIRED" &&
                                                                        field.code !==
                                                                            "accountFlag1"
                                                                    }
                                                                />
                                                            )
                                                        }
                                                        return undefined
                                                    }
                                                )}

                                                <Box>
                                                    {mode === "add" ? (
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                marginTop:
                                                                    "20px",
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                disabled={
                                                                    formikProps.isSubmitting
                                                                }
                                                                onClick={() =>
                                                                    onCancelClick()
                                                                }
                                                            >
                                                                <FormattedMessage
                                                                    id="generalTerms.cancel"
                                                                    defaultMessage="Cancel"
                                                                />
                                                            </Button>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={
                                                                    formikProps.isSubmitting
                                                                }
                                                            >
                                                                <FormattedMessage
                                                                    id="generalTerms.submit"
                                                                    defaultMessage="Submit"
                                                                />
                                                            </Button>
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            <Box
                                                                sx={{
                                                                    width:
                                                                        "100%",
                                                                    marginTop:
                                                                        "20px",
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "row",
                                                                    justifyContent:
                                                                        "space-between",
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    disabled={
                                                                        formikProps.isSubmitting
                                                                    }
                                                                    onClick={() =>
                                                                        onCancelClick()
                                                                    }
                                                                >
                                                                    <FormattedMessage
                                                                        id="generalTerms.cancel"
                                                                        defaultMessage="Cancel"
                                                                    />
                                                                </Button>
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={
                                                                        formikProps.isSubmitting
                                                                    }
                                                                >
                                                                    <FormattedMessage
                                                                        id="generalTerms.update"
                                                                        defaultMessage="Update"
                                                                    />
                                                                </Button>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    marginTop:
                                                                        "10px",
                                                                    width:
                                                                        "100%",
                                                                }}
                                                            >
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "100%",
                                                                    }}
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() =>
                                                                        handleDialogOpen()
                                                                    }
                                                                >
                                                                    <FormattedMessage
                                                                        id="editCarrier.removeCarrier"
                                                                        defaultMessage="Remove Carrier"
                                                                    />
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Box>

                                                <Dialog
                                                    open={dialogOpen}
                                                    onClose={handleDialogClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle id="alert-dialog-title">
                                                        {"Confirm"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <FormattedMessage
                                                                id="editCarrier.deleteConfirmation"
                                                                defaultMessage="Are you sure you want to delete this carrier account? This cannot be undone."
                                                            />
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Box
                                                            sx={{
                                                                padding: "10px",
                                                                width: "100%",
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={
                                                                    handleDialogClose
                                                                }
                                                                color="secondary"
                                                                variant="outlined"
                                                            >
                                                                <FormattedMessage
                                                                    id="generalTerms.cancel"
                                                                    defaultMessage="Cancel"
                                                                />
                                                            </Button>
                                                            <Button
                                                                onClick={
                                                                    removeCarrier
                                                                }
                                                                color="secondary"
                                                                variant="contained"
                                                                autoFocus
                                                            >
                                                                <FormattedMessage
                                                                    id="generalTerms.delete"
                                                                    defaultMessage="Delete"
                                                                />
                                                            </Button>
                                                        </Box>
                                                    </DialogActions>
                                                </Dialog>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </Box>
                        </Paper>
                        <Box
                            sx={{
                                width: "70%",
                                padding: "0 20px",
                            }}
                        >
                            <Paper
                                style={{
                                    background: "lightGray",
                                    padding: "10px",
                                }}
                            >
                                <Tutorial code={carrierId} />
                            </Paper>
                        </Box>
                    </Box>
                </>
            ) : null}
        </>
    )
}

export default CarrierForm
