import React, { Component } from "react"
import { get } from "lodash"
import { Field, FormSection } from "redux-form"
import FormCheckbox from "../../form/form-checkbox"
import { withStyles, Grid } from "@material-ui/core"

const styles = theme => ({
    filterRow: {
        borderBottom: "Solid 1px #D4D4D4",
        width: "100%",
    },
    mainContainer: {
        width: "100%",
    },
})

const FilterRow = ({ carrier, classes, gaCategory }) => (
    <Grid item container alignItems="center" className={classes.filterRow}>
        <Grid item container>
            <Field
                name={get(carrier, "carrier_id")}
                label={`${get(carrier, "name")} (${get(
                    carrier,
                    "carrier_id"
                )})`}
                component={FormCheckbox}
                category={gaCategory}
            />
        </Grid>
    </Grid>
)

class DashboardCarrierFilter extends Component {
    render() {
        const { classes, carriers, gaCategory, validCarrierIds } = this.props
        const alphabeticCarrierCodes = [
            "FXNL",
            "FXFE",
            ...Object.keys(carriers)
                .filter(carrierCode => validCarrierIds.has(carrierCode))
                .filter(
                    carrierCode =>
                        carrierCode !== "FXFE" && carrierCode !== "FXNL"
                )
                .sort((a, b) =>
                    carriers[a].name.localeCompare(carriers[b].name)
                ),
        ]

        return (
            <Grid item container>
                <FormSection
                    name="carrierFilter"
                    className={classes.mainContainer}
                >
                    {alphabeticCarrierCodes.map(carrier => (
                        <FilterRow
                            classes={classes}
                            carrier={carriers[carrier]}
                            gaCategory={gaCategory}
                        />
                    ))}
                </FormSection>
            </Grid>
        )
    }
}

export default withStyles(styles)(DashboardCarrierFilter)
