import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { MARKUP_FIELD_CHANGE } from "../actions/quote"
import { QUOTE_RESULT } from "../actions/quote-request"
import {
    CARRIER_LOAD,
    CARRIER_LOAD_ERROR,
    CARRIER_RESULT,
    SET_ONBOARDING_LOCATION,
} from "../actions/carrier"
import {
    END_TIMER,
    REGISTER_REQUEST,
    RELEASE_REQUEST,
    START_TIMER,
    FLAG_NEW_VERSION_AVAILABLE,
} from "../actions"
import {
    BOL_DOCUMENT_ERROR,
    BOL_DOCUMENT_FETCH,
    BOL_DOCUMENT_RESULT,
    SHIPPING_LABEL_RESULT,
    SHIPPING_LABEL_FETCH,
    SHIPPING_LABEL_ERROR,
} from "../actions/modal"
import {
    TERMS_LOAD,
    TERMS_LOAD_ERROR,
    TERMS_RECEIVED,
    TERMS_VERSION_RECEIVED,
} from "../actions/terms"
import {
    IMPERSONATION_WARNING,
    IMPERSONATION_ENABLED,
} from "../actions/authorization"
import { addressModal } from "./address"
import { book } from "./book"
import { cpg } from "./cpg"
import { quotes } from "./quote"
import { shipment } from "./shipment"
import { chat } from "./chat"
import { user } from "./user"
import { modal } from "./modal"
import { share } from "./share"
import { alerts } from "./alerts"
import { shareStatus } from "./share-status"
import { search, searchItem } from "./search"
import { postalCode } from "./postal-code"
import { addressBook } from "./address-book"
import { discounts } from "./freightdirect"
import { redesign } from "./redesign"
import { dashboard } from "./dashboard"
import { authorization } from "./authorization"
import { error } from "./error"
import { item } from "./item"
import { contact } from "./contact"
import { vagueCommodities } from "./vague-commodity"
import { trailerManifest } from "./trailerManifest"
import { offshoreRating } from "./offshoreRating"
import { accessorials } from "./accessorial"
import { get } from "lodash"
import {
    RESET_VALIDATION_SCROLL,
    SCROLLED_TO_VALIDATION,
} from "../actions/validation"
import { USER_DISAUTHENTICATED } from "../actions/user"

export const removeItemFromArray = (from, name, index) => ({
    ...from,
    [name]: from[name].filter((_, i) => i !== index),
})

export function fieldValidationReducer(state, action, form) {
    if (action.form !== form) return state
    return { ...state, validation: action.validation }
}

export function carriers(
    state = {
        isFetching: false,
        isLoaded: false,
        isError: false,
        carriers: {},
        onboardingLocation: null,
    },
    action
) {
    switch (action.type) {
        case CARRIER_LOAD:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
            })
        case CARRIER_RESULT:
            return Object.assign({}, state, {
                carriers: action.carriers,
                isFetching: false,
                isLoaded: true,
                isError: false,
            })
        case CARRIER_LOAD_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
            })
        case SET_ONBOARDING_LOCATION:
            return {
                ...state,
                onboardingLocation: action.location,
            }
        default:
            return state
    }
}

export function identifiers(
    state = {
        bolNumber: "",
        referenceNumber: "",
        proNumber: "",
        orderNumber: "",
        internalTrackingNumber: "",
    },
    action
) {
    switch (action.type) {
        case QUOTE_RESULT:
            return { ...state, ...action.identifiers }
        default:
            return state
    }
}

export function markup(
    state = { markupAmount: "", markupType: "markupAmount" },
    action
) {
    switch (action.type) {
        case QUOTE_RESULT:
            return {
                markupAmount: get(action, "search.markupAmount") || 0,
                markupType:
                    get(action, "search.markupType") || "markupPercentage",
            }
        case MARKUP_FIELD_CHANGE:
            return { ...state, [action.field]: action.value }
        default:
            return state
    }
}

export function bolDocument(state = {}, action) {
    switch (action.type) {
        case BOL_DOCUMENT_RESULT:
            return {
                ...state,
                [action.shipmentId]: {
                    data: action.data,
                    isError: false,
                    isFetching: false,
                },
            }
        case BOL_DOCUMENT_ERROR:
            return {
                ...state,
                [action.shipmentId]: {
                    ...state[action.shipmentId],
                    isError: true,
                    isFetching: false,
                },
            }
        case BOL_DOCUMENT_FETCH:
            return {
                ...state,
                [action.shipmentId]: {
                    ...state[action.shipmentId],
                    isFetching: true,
                },
            }
        default:
            return state
    }
}

export function shippingLabelDocument(state = {}, action) {
    switch (action.type) {
        case SHIPPING_LABEL_RESULT:
            return {
                ...state,
                [action.shipmentId]: {
                    data: action.data,
                    isError: false,
                    isFetching: false,
                },
            }
        case SHIPPING_LABEL_ERROR:
            return {
                ...state,
                [action.shipmentId]: {
                    ...state[action.shipmentId],
                    isError: true,
                    isFetching: false,
                },
            }
        case SHIPPING_LABEL_FETCH:
            return {
                ...state,
                [action.shipmentId]: {
                    ...state[action.shipmentId],
                    isFetching: true,
                },
            }
        default:
            return state
    }
}

export function terms(
    state = {
        version: "",
        description: "",
        isFetching: false,
        isLoaded: false,
        isError: false,
    },
    action
) {
    switch (action.type) {
        case TERMS_VERSION_RECEIVED:
            return { ...state, version: action.version }
        case TERMS_LOAD:
            return { ...state, isFetching: true }
        case TERMS_LOAD_ERROR:
            return { ...state, isFetching: false, isError: true }
        case TERMS_RECEIVED:
            return {
                ...state,
                description: action.description,
                version: action.version,
                language: action.language,
                isFetching: false,
                isError: false,
                isLoaded: true,
            }
        default:
            return state
    }
}

export function validation(state = { needScroll: true }, action) {
    switch (action.type) {
        case RESET_VALIDATION_SCROLL:
            return { needScroll: true }
        case SCROLLED_TO_VALIDATION:
            return { needScroll: false }
        default:
            return state
    }
}

export function timers(state = {}, action) {
    switch (action.type) {
        case START_TIMER:
            return { ...state, [action.id]: action.time }
        case END_TIMER:
            delete state[action.id]
            return { ...state }
        default:
            return state
    }
}

export function requests(state = {}, action) {
    switch (action.type) {
        case REGISTER_REQUEST:
            return { ...state, [action.id]: action.emitter }
        case RELEASE_REQUEST:
            delete state[action.id]
            return { ...state }
        default:
            return state
    }
}

export function newVersionAvailable(state = false, action) {
    switch (action.type) {
        case FLAG_NEW_VERSION_AVAILABLE:
            return true
        default:
            return state
    }
}

export function impersonation(state = { enabled: false }, action) {
    switch (action.type) {
        case USER_DISAUTHENTICATED:
            return { enabled: false }
        case IMPERSONATION_WARNING:
            return { ...state, email: action.email }
        case IMPERSONATION_ENABLED:
            return { ...state, enabled: action.enabled }
        default:
            return state
    }
}

const dashboardPersistConfig = {
    key: "dashboard",
    storage,
    whitelist: ["filters", "dashboardFilters"],
}

export const rootReducer = combineReducers({
    quotes,
    search,
    searchItem,
    carriers,
    cpg,
    user,
    shipment,
    book,
    chat,
    modal,
    accessorials,
    share,
    alerts,
    shareStatus,
    dashboard: persistReducer(dashboardPersistConfig, dashboard),
    identifiers,
    addressModal,
    postalCode,
    bolDocument,
    redesign,
    impersonation,
    shippingLabelDocument,
    addressBook,
    discounts,
    terms,
    authorization,
    markup,
    error,
    item,
    validation,
    contact,
    timers,
    requests,
    newVersionAvailable,
    vagueCommodities,
    trailerManifest,
    offshoreRating,
    form: formReducer,
})
