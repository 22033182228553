import React, { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux"
import createCachedSelector from "re-reselect"
import { createSelector } from "reselect"
import {
    Divider,
    Grid,
    Button,
    withStyles,
    ExpansionPanel,
    ExpansionPanelActions,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Snackbar,
    Typography,
    Badge,
    IconButton,
    Popover,
} from "@material-ui/core"
import ExpandMore from "@material-ui/icons/ExpandMore"
import WarningIcon from "@material-ui/icons/Warning"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import moment from "moment"
import { get } from "lodash"
import RateBreakdown from "./components/rateBreakdown"
import MasterTimeline, {
    statusStepSelector,
} from "../shipmentTimeline/masterTimeline"
import SnackbarContentWrapper from "../quote/share/snackbar"
import { accessorialsSelector } from "../util/accessorialsDisplay"
import TerminalInfo from "../common/TerminalInfo"
import AccessorialsDisplay from "./accessorialsDisplay"
import ItemsDisplay from "./itemsDisplay"
import { FormattedMessage, injectIntl } from "react-intl"
import { currencyLabel } from "../util/currencyLabel"
import MiniAttachments from "../track/result/attachments/miniAttachments"
import { changePath } from "../../actions"
import { trackGAEvent } from "../../actions/user"
import { changeArchiveStatus, changeStarredStatus } from "../../actions/track"
import { useTileCountContext } from "../../context/providers/TileCountProvider"
import { dismissAlert } from "../../actions/alerts"
import DashboardItemAlertBox from "./DashboardItemAlertBox"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { JAX, SJU } from "../../actions/util/domesticOffshoreTerminal"
import { useFlags } from "launchdarkly-react-client-sdk"
import { isFedExCarrier } from "../../misc"
import { containsCombinedOtherRatesAndFedexOceanRates } from "../util/carrierLogoUtils"
import { isOffshorePRShipmentFedexValid } from "../util/offshoreUtils."
import CancelPickupDialog from "./CancelPickupDialog"
import { goFetch } from "../../http"
import GlobalSpinner from "../common/GlobalSpinner"

const GACategory = "Dashboard Item"

const styles = theme => ({
    parent__container: {
        "min-width": "870px",
        "flex-grow": 1,
        "flex-shrink": 1,
        "flex-basis": "0px",
    },
    parent__container__open: {
        marginBottom: "10px !important",
    },
    panel__summary: {
        height: "84px",
        "&:hover": {
            backgroundColor: "#F4F4F4",
        },
        paddingLeft: "8px",
    },
    item__container: {
        overflow: "visible",
    },
    button__info: {
        "margin-right": "15%",
    },
    button__icon: {
        "&button": {
            "&:focus": {
                outline: "none",
            },
        },
    },
    content__container: {
        "white-space": "pre-line",
        display: "flex",
        padding: "10px",
    },
    address__container__open: {
        "white-space": "pre-line",
        display: "flex",
        width: "50%",
    },
    address__box__left: {
        "min-width": "25%",
    },
    address__box__right: {
        "min-width": "75%",
    },
    actions: {
        padding: "4px 16px 8px 8px",
    },
    directionIcon: {
        width: "35px",
        height: "35px",
    },
    carrier__logo: {
        "max-height": "70px",
        "max-width": "100px",
    },
    carrier__tag: {
        "margin-left": "8px",
    },
    item__description: {
        "margin-left": "16px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "flex-start",
    },
    item__total: {
        "margin-left": "16px",
        "margin-top": "10px",
    },
    caption_inline: {
        display: "inline",
        "margin-left": "16px",
    },
    hide: {
        display: "none",
        "margin-left": "16px",
    },
    identifiers__container: {
        "white-space": "nowrap",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    identifiers__box__left: {
        "min-width": "40%",
    },
    identifiers__box__right: {
        "min-width": "60%",
    },
    badge: {
        badge: {
            top: 1,
            right: -15,
        },
    },
    expansionPanelDetails__topContainer: {
        margin: "0 30px",
    },
    expansionPanelDetails__middleContainer: {
        margin: "0 30px",
        padding: "10px 0px",
    },
    expansionPanelDetails__bottomContainer: {
        margin: "0 30px",
        padding: "10px 0px",
    },
    expansionPanelDetails__section: {
        padding: "10px",
    },
    section__title: {
        textDecoration: "underline",
    },
    rateBreakdown__container: {
        paddingTop: "10px",
    },
    BOLNumber: {
        paddingTop: "3px",
    },
    copyableContainer: {
        paddingLeft: "8px",
        cursor: "text",
        userSelect: "text",
    },
    paper: {
        maxWidth: "675px",
        minWidth: "500px",
    },
    actionButton: {
        margin: "5px",
    },
})

const AddressPart = ({ data, title }) => {
    return data ? (
        <Grid item container xs={6}>
            <Grid
                item
                container
                justifyContent="flex-start"
                direction="row"
                xs={12}
            >
                <Grid item>
                    <Typography variant="caption">{title}</Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                justifyContent="flex-start"
                direction="column"
                xs={12}
            >
                <Grid item>
                    {get(data, "name", "") !== "" && (
                        <Typography variant="body2">{data.name}</Typography>
                    )}
                </Grid>
                <Grid item>
                    {get(data, "address.street1", "") !== "" && (
                        <Typography variant="body2">
                            {data.address.street1}
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    {get(data, "address", "") !== "" && (
                        <Typography variant="body2">
                            {data.address.city}, {data.address.state}{" "}
                            {data.address.postalCode}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    ) : null
}

const DashboardShipmentItem = ({
    identifiers,
    isOpen,
    shipment,
    classes,
    carrier,
    onClickDetails,
    onShipAgain,
    accessorials,
    onViewQuote,
    intl,
    onCancelShipment,
    shipmentId,
    toggleInfo,
    currentStatus,
    canShip,
    activeAlerts,
    canViewRateAmounts,
    index,
    status,
    handlingUnits,
    totalWeight,
    totalHandlingUnits,
    fetchElements,
    page,
    pageSize,
    hasFXFDReturnsLocation,
    query,
    shipmentLocation,
    owner,
    trackGA,
    alerts,
    dismissAlert,
    activeDashboardTile,
    changeArchiveStatus,
    viewArchived,
    changeStarredStatus,
    legs,
}) => {
    let pickupTerminal = shipment?.rate?.terminal
    const { canadaMexicoLocationModal, pickupCancel } = useFlags()
    if (legs.length > 1 && canadaMexicoLocationModal) {
        const firstLegDelivered =
            legs[0]?.status?.currentStatus?.code === "DELIVERED"
        pickupTerminal = firstLegDelivered
            ? legs[1]?.shipment?.rate?.terminal
            : legs[0]?.shipment?.rate?.terminal
    }
    const [state, setState] = useState({
        openCancelShipmentDialog: false,
        snackbarOpen: false,
        snackbarVariant: "",
        cancelSuccess: "",
        cancelError: "",
        loading: false,
        anchorEl: null,
    })
    const [shipmentsToCancel, setShipmentsToCancel] = useState([])
    const [isValidImage, setIsValidImage] = useState(false)
    const {
        decrementTileCount,
        incrementTileCount,
        getSelectedTiles,
        setTileCount,
    } = useTileCountContext()
    const { openSnackbar } = useSnackbarContext()

    useEffect(() => {
        const img = new Image()
        img.src = carrier?.logo
        img.onload = () => setIsValidImage(true)
        img.onerror = () => setIsValidImage(false)
    }, [])

    const handleAlertBoxOpen = event => {
        trackGA(GACategory, "Alert Box Opened")
        setState(prevState => ({
            ...prevState,
            anchorEl: event.currentTarget,
        }))
    }

    const handleAlertBoxClose = () => {
        setState(prevState => ({
            ...prevState,
            anchorEl: null,
        }))
    }

    const handleCancelShipmentDialogClose = () => {
        setState(prevState => ({
            ...prevState,
            openCancelShipmentDialog: false,
        }))
        setShipmentsToCancel([])
    }

    const handleCancelShipmentDialogOpen = async shipmentId => {
        setLoading(true)
        try {
            if (pickupCancel) {
                const { data } = await goFetch(
                    `/shipments/pickup/${shipmentId}`,
                    { method: "GET", credentials: "same-origin" },
                    true
                )
                if (data?.list?.length > 0) {
                    setShipmentsToCancel(data?.list)
                }
            }
        } catch (err) {}
        setState(prevState => ({
            ...prevState,
            openCancelShipmentDialog: true,
        }))
        setLoading(false)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setState(prevState => ({
            ...prevState,
            snackbarOpen: false,
        }))
    }

    const setLoading = bool => {
        setState(prevState => ({
            ...prevState,
            loading: bool,
        }))
    }

    const handleSnackbarOpen = (variant, info) => {
        variant === "error"
            ? setState(prevState => ({
                  ...prevState,
                  snackbarOpen: true,
                  snackbarVariant: variant,
                  cancelError: `${info.response.message.errorMessage}: \n ${info.response.message.errors[0].message}`,
              }))
            : setState(prevState => ({
                  ...prevState,
                  snackbarOpen: true,
                  snackbarVariant: variant,
                  cancelSuccess: `Shipment ${info.bolNumber} successfully cancelled.`,
              }))
    }

    const handleReturnShipment = () => {
        trackGA("FXFD-Returns", "Dashboard Return Button Click")
        changePath(`/book/${shipmentId}/return`)
    }

    const {
        snackbarOpen,
        snackbarVariant,
        cancelSuccess,
        cancelError,
        loading,
    } = state
    const shipmentStatus = get(currentStatus, "status", "")
    const shipmentStatusIdentifier = currentStatus?.statusIdentifier
    const canCancelShipment =
        get(carrier, "features.cancel_dispatch") &&
        shipment?.isCancelable !== false
    const carrierName = get(carrier, "name")
    const carrierCode = get(carrier, "carrier_id")
    const usePreferredCurrency =
        get(shipment, "rate.rateQuoteDetail.totalInPreferredCurrency") !== null

    const openClasses = {
        expanded: classes.parent__container__open,
    }

    const isFreightDirect = shipment?.rate?.mode === "LTL_DIRECT"
    const isFreightDirectReturns = shipment?.rate?.mode === "LTL_DIRECT_RETURNS"
    const serviceLevel = shipment?.rate?.serviceLevel
    const isVendorAccount = shipment?.rate?.accountType === "VENDOR"

    const eligibleForReturn =
        currentStatus?.code === "DELIVERED" &&
        hasFXFDReturnsLocation &&
        isFreightDirect

    const { isQuickRate, isQuickQuote, queryVersion, pickupDate } = query

    const shipmentCreator = shipmentLocation?.users.find(user => {
        return user?.user?.id === owner
    })
    const shipmentCreatedByFullName =
        shipmentCreator?.user?.firstname + " " + shipmentCreator?.user?.lastname

    const alertBoxOpen = Boolean(state.anchorEl)

    const isIntraMexico =
        shipment?.origin?.address?.country === "MX" &&
        shipment?.destination?.address?.country === "MX"

    const isOriginPRShipment = shipment?.origin?.address?.state === "PR"
    const isDestinationPRShipment =
        shipment?.destination?.address?.state === "PR"

    const isPRShipmentFedexValid = isOffshorePRShipmentFedexValid(
        shipment?.origin?.address?.country,
        shipment?.origin?.address?.state,
        shipment?.destination?.address?.country,
        shipment?.destination?.address?.state
    )

    const { domesticOffshoreRating, dashboardShipperLogo } = useFlags()

    const containsCombinedOtherRatesAndFedexOceanRatesFlag = containsCombinedOtherRatesAndFedexOceanRates(
        shipment?.rate
    )

    const getCorrectLogoName = name =>
        containsCombinedOtherRatesAndFedexOceanRatesFlag
            ? name + " - FedEx "
            : name

    const carrierRename = getCorrectLogoName(carrierName)

    const carrierNewCode = getCorrectLogoName(carrierCode)

    const isFedExShipment = ["FXFE", "FXNL"].includes(carrierCode)

    const getCarrierLogo = () => {
        if (isFedExShipment) {
            return (
                <img
                    className={classes.carrier__logo}
                    src="https://cloud.p-44.com/img/carriers/FXFElogo.png"
                    alt={carrierCode}
                />
            )
        } else if (dashboardShipperLogo) {
            if (isValidImage) {
                return (
                    <img
                        className={classes.carrier__logo}
                        src={carrier?.logo}
                        alt={carrierCode}
                    />
                )
            } else {
                return (
                    <Typography
                        variant="subtitle1"
                        className={classes.carrier__tag}
                    >{`${carrierRename}`}</Typography>
                )
            }
        } else {
            return (
                <Typography
                    variant="subtitle1"
                    className={classes.carrier__tag}
                >{`${carrierRename}`}</Typography>
            )
        }
    }

    return (
        <div className="shipment-item">
            {loading ? <GlobalSpinner /> : null}
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContentWrapper
                    variant={snackbarVariant}
                    className={classes.margin}
                    message={
                        snackbarVariant === "success"
                            ? cancelSuccess
                            : cancelError
                    }
                    onClose={handleSnackbarClose}
                />
            </Snackbar>
            <ExpansionPanel
                id={`dashboardShipmentItem__${index}`}
                className={classes.parent__container}
                classes={openClasses}
                expanded={isOpen}
            >
                <ExpansionPanelSummary
                    className={classes.panel__summary}
                    expandIcon={
                        <ExpandMore
                            id={`dashboardShipmentItemExpandMore__${index}`}
                        />
                    }
                    onClick={e => {
                        toggleInfo(shipment._id)
                    }}
                >
                    <Grid container>
                        <Grid container item xs={4}>
                            <Grid container item alignItems="center" xs={2}>
                                <IconButton
                                    onClick={e => {
                                        const isFavorite = !shipment?.isFavorite
                                        changeStarredStatus(
                                            shipmentId,
                                            isFavorite,
                                            activeDashboardTile === "starred",
                                            openSnackbar,
                                            isFavorite
                                                ? incrementTileCount
                                                : decrementTileCount,
                                            isFavorite
                                                ? decrementTileCount
                                                : incrementTileCount
                                        )
                                        e.stopPropagation()
                                    }}
                                >
                                    {shipment?.isFavorite ? (
                                        <StarIcon color="primary" />
                                    ) : (
                                        <StarBorderIcon color="primary" />
                                    )}
                                </IconButton>
                            </Grid>
                            <Grid item container xs={5} alignItems="center">
                                {getCarrierLogo()}
                                {carrierCode && (
                                    <Typography
                                        variant="caption"
                                        className={classes.carrier__tag}
                                    >{`(${carrierNewCode})`}</Typography>
                                )}
                            </Grid>

                            <Grid
                                item
                                container
                                direction="column"
                                xs={5}
                                className={classes.copyableContainer}
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                            >
                                {identifiers.proNumber && (
                                    <Grid item>
                                        <Typography variant="caption">
                                            <FormattedMessage
                                                id="generalTerms__trackingId"
                                                defaultMessage="Tracking ID"
                                            />
                                        </Typography>
                                        <Typography variant="body2">
                                            {identifiers.proNumber}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item className={classes.BOLNumber}>
                                    <Typography variant="caption">
                                        <FormattedMessage
                                            id="generalTerms__BOL"
                                            defaultMessage="BOL"
                                        />
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        id={`dashboardShipmentItem__${index}__bolNumber`}
                                    >
                                        {identifiers.bolNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent="center"
                            direction="column"
                            xs={4}
                        >
                            {get(shipment, "destination.name", "") !== "" && (
                                <div className={classes.content__container}>
                                    <span
                                        className={classes.address__box__right}
                                    >
                                        <Typography variant={"subtitle1"}>
                                            {`${shipment.origin.name}`} &#8594;{" "}
                                            {`${shipment.destination.name}`}
                                        </Typography>
                                    </span>
                                </div>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            xs={2}
                        >
                            {get(shipment, "rate.rateQuoteDetail") &&
                                canViewRateAmounts &&
                                !get(shipment, "rate.isDomesticOffshore") && (
                                    <Typography variant={"subtitle1"}>
                                        {currencyLabel(
                                            shipment?.rate
                                                ?.preferredCurrencyCode,
                                            usePreferredCurrency
                                                ? get(
                                                      shipment,
                                                      "rate.rateQuoteDetail.totalInPreferredCurrency"
                                                  )
                                                : get(
                                                      shipment,
                                                      "rate.rateQuoteDetail.total"
                                                  )
                                        )}
                                    </Typography>
                                )}
                        </Grid>
                        <Grid item container xs={2}>
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="flex-end"
                                alignContent="center"
                                xs={8}
                            >
                                <Grid
                                    item
                                    container
                                    justifyContent="flex-end"
                                    xs={12}
                                >
                                    <Typography
                                        align="right"
                                        id={`dashboardShipmentItem__${index}__status`}
                                        variant={"subtitle1"}
                                    >
                                        {get(status, "currentStatus.code") ===
                                        "CANCELED" ? (
                                            <FormattedMessage
                                                id="generalTerms__canceled"
                                                defaultMessage="CANCELED"
                                            />
                                        ) : (
                                            shipmentStatus
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justifyContent="flex-end"
                                    xs={12}
                                >
                                    <Typography align="right" variant="caption">
                                        {get(status, "currentStatus.code") ===
                                        "CANCELED"
                                            ? moment(
                                                  get(
                                                      status,
                                                      "currentStatus.timestamp"
                                                  )
                                              ).format("M/DD/YY")
                                            : moment(
                                                  get(
                                                      currentStatus,
                                                      "latestEventTime"
                                                  )
                                              ).format("M/DD/YY")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {activeAlerts.length > 0 && (
                                <Grid
                                    item
                                    container
                                    xs={4}
                                    justifyContent="center"
                                    alignItems="center"
                                    onClick={e => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <Button onClick={handleAlertBoxOpen}>
                                        <Badge
                                            badgeContent={activeAlerts.length}
                                            color="secondary"
                                            classes={{
                                                badge: classes.badge,
                                            }}
                                        >
                                            <WarningIcon
                                                id={`dashboardShipmentItemWarningIcon__${index}`}
                                            />
                                        </Badge>
                                    </Button>
                                    <Popover
                                        classes={{ paper: classes.paper }}
                                        open={alertBoxOpen}
                                        anchorEl={state.anchorEl}
                                        onClose={handleAlertBoxClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                    >
                                        <Grid item container xs={12}>
                                            {alerts?.events?.map(
                                                (event, index) => {
                                                    if (
                                                        event.type ===
                                                            "warning" &&
                                                        !event.dismissed
                                                    ) {
                                                        return (
                                                            <Grid
                                                                item
                                                                container
                                                            >
                                                                <DashboardItemAlertBox
                                                                    key={index}
                                                                    event={
                                                                        event
                                                                    }
                                                                    dismissAlert={
                                                                        dismissAlert
                                                                    }
                                                                    shipmentId={
                                                                        shipmentId
                                                                    }
                                                                    proNumber={
                                                                        event.proNumber
                                                                    }
                                                                    trackGA={
                                                                        trackGA
                                                                    }
                                                                />
                                                            </Grid>
                                                        )
                                                    }
                                                    return null
                                                }
                                            )}
                                        </Grid>
                                    </Popover>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid
                            item
                            container
                            xs={12}
                            className={
                                classes.expansionPanelDetails__topContainer
                            }
                            alignContent="flex-start"
                        >
                            <Grid
                                item
                                container
                                xs={4}
                                className={
                                    classes.expansionPanelDetails__section
                                }
                            >
                                <Grid item container>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.section__title}
                                    >
                                        {" "}
                                        <FormattedMessage
                                            id="dashboard__routingSummary"
                                            defaultMessage="Routing Summary"
                                        />{" "}
                                    </Typography>
                                </Grid>
                                <AddressPart
                                    data={shipment.origin}
                                    title={`${intl.formatMessage({
                                        id: "generalTerms__from",
                                        defaultMessage: "From",
                                    })}:`}
                                />
                                <AddressPart
                                    data={shipment.destination}
                                    title={`${intl.formatMessage({
                                        id: "generalTerms__to",
                                        defaultMessage: "To",
                                    })}:`}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={2}
                                className={
                                    classes.expansionPanelDetails__section
                                }
                            >
                                {(get(identifiers, "orderNumber") ||
                                    get(identifiers, "referenceNumber")) && (
                                    <Grid item container>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.section__title}
                                        >
                                            <FormattedMessage
                                                id="dashboard__identifiers"
                                                defaultMessage="Identifiers"
                                            />
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    className={classes.item__container}
                                    xs={12}
                                >
                                    {get(identifiers, "orderNumber") && (
                                        <Grid
                                            container
                                            item
                                            className={
                                                classes.identifiers__container
                                            }
                                            direction="column"
                                        >
                                            <Grid
                                                item
                                                className={
                                                    classes.identifiers__box__left
                                                }
                                            >
                                                <Typography variant="caption">
                                                    <FormattedMessage
                                                        id="generalTerms__PO"
                                                        defaultMessage="PO"
                                                    />
                                                    :{" "}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                className={
                                                    classes.identifiers__box__right
                                                }
                                            >
                                                <Typography variant="body2">
                                                    {identifiers.orderNumber}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {get(identifiers, "referenceNumber") && (
                                        <Grid
                                            item
                                            container
                                            className={
                                                classes.identifiers__container
                                            }
                                            direction="column"
                                            xs={12}
                                        >
                                            <Grid
                                                className={
                                                    classes.identifiers__box__left
                                                }
                                                item
                                            >
                                                <Typography variant="caption">
                                                    {" "}
                                                    <FormattedMessage
                                                        id="generalTerms__referenceId"
                                                        defaultMessage="Reference ID"
                                                    />
                                                    :{" "}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                className={
                                                    classes.identifiers__box__right
                                                }
                                                item
                                            >
                                                <Typography variant="body2">
                                                    {
                                                        identifiers.referenceNumber
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={3}
                                justifyContent="flex-end"
                                className={
                                    classes.expansionPanelDetails__section
                                }
                            >
                                <MiniAttachments
                                    internalTrackingNumber={shipmentId}
                                    id={`dashboardShipmentItemMiniAttachments__${index}`}
                                    gaCategory={GACategory}
                                />
                            </Grid>
                            {(shipment?.rate?.terminal ||
                                shipment?.rate?.destinationTerminal) && (
                                <Grid
                                    item
                                    container
                                    xs={3}
                                    className={
                                        classes.expansionPanelDetails__section
                                    }
                                >
                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                        className={
                                            classes.terminalInfo__container
                                        }
                                    >
                                        {pickupTerminal && (
                                            <TerminalInfo
                                                reverse
                                                isPickup
                                                terminal={
                                                    isPRShipmentFedexValid &&
                                                    isOriginPRShipment &&
                                                    domesticOffshoreRating &&
                                                    (containsCombinedOtherRatesAndFedexOceanRates(
                                                        shipment?.rate
                                                    ) ||
                                                        isFedExCarrier(
                                                            shipment?.rate
                                                                ?.carrierCode
                                                        ))
                                                        ? SJU
                                                        : pickupTerminal
                                                }
                                                isIntraMexico={isIntraMexico}
                                            />
                                        )}
                                        {shipment.rate?.destinationTerminal && (
                                            <TerminalInfo
                                                reverse
                                                isPickup={false}
                                                terminal={
                                                    isPRShipmentFedexValid &&
                                                    isDestinationPRShipment &&
                                                    domesticOffshoreRating &&
                                                    (containsCombinedOtherRatesAndFedexOceanRates(
                                                        shipment?.rate
                                                    ) ||
                                                        isFedExCarrier(
                                                            shipment?.rate
                                                                ?.carrierCode
                                                        ))
                                                        ? JAX
                                                        : shipment?.rate
                                                              ?.destinationTerminal
                                                }
                                                isIntraMexico={isIntraMexico}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            className={
                                classes.expansionPanelDetails__bottomContainer
                            }
                            justifyContent="center"
                        >
                            <MasterTimeline
                                totalWeight={totalWeight}
                                handlingUnits={handlingUnits}
                                shipment={shipment}
                                shipmentId={shipmentId}
                                identifiers={identifiers}
                                preferredSystemOfMeasurement={
                                    query?.preferredSystemOfMeasurement
                                }
                                pickupDate={pickupDate}
                            />
                            {legs.map(childShipment => {
                                return (
                                    <MasterTimeline
                                        isChildLeg={true}
                                        key={childShipment?._id}
                                        totalWeight={childShipment?.totalWeight}
                                        handlingUnits={
                                            childShipment?.handlingUnits
                                        }
                                        shipment={childShipment?.shipment}
                                        pickupDate={
                                            childShipment?.query?.pickupDate
                                        }
                                        preferredSystemOfMeasurement={
                                            query?.preferredSystemOfMeasurement
                                        }
                                        shipmentId={
                                            childShipment?.identifiers
                                                ?.internalTrackingNumber
                                        }
                                        identifiers={childShipment?.identifiers}
                                    />
                                )
                            })}
                        </Grid>
                        <Grid
                            item
                            container
                            className={
                                classes.expansionPanelDetails__middleContainer
                            }
                            alignContent="flex-start"
                        >
                            {shipmentLocation?.users[0]?.role === "owner" ? (
                                <Grid item container>
                                    <Typography variant="caption">
                                        <FormattedMessage
                                            id="shipment_created_by"
                                            defaultMessage="Shipment Created By: "
                                        />
                                        {shipmentCreatedByFullName}
                                    </Typography>
                                </Grid>
                            ) : null}
                            <Grid
                                item
                                container
                                xs={6}
                                className={
                                    classes.expansionPanelDetails__section
                                }
                            >
                                <ItemsDisplay
                                    preferredSystemOfMeasurement={
                                        query?.preferredSystemOfMeasurement
                                    }
                                    handlingUnits={handlingUnits}
                                    totalWeight={totalWeight}
                                    totalHandlingUnits={totalHandlingUnits}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={3}
                                className={
                                    classes.expansionPanelDetails__section
                                }
                                alignContent="flex-start"
                            >
                                {accessorials && (
                                    <AccessorialsDisplay
                                        accessorials={accessorials}
                                        isFreightDirect={isFreightDirect}
                                        isFreightDirectReturns={
                                            isFreightDirectReturns
                                        }
                                        serviceLevel={serviceLevel}
                                        isInBondShipment={
                                            shipment?.isInBondShipment
                                        }
                                        id={`dashboardShipmentItem__${index}__accesorials`}
                                    />
                                )}
                            </Grid>
                            {canViewRateAmounts &&
                                !shipment?.isInBondShipment &&
                                !isVendorAccount &&
                                serviceLevel?.code !==
                                    "BASIC_PICKUP_AND_DESTROY" && (
                                    <Grid
                                        item
                                        container
                                        xs={3}
                                        justifyContent="flex-end"
                                        alignContent="flex-start"
                                    >
                                        <Grid
                                            item
                                            container
                                            justifyContent="flex-end"
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                className={
                                                    classes.section__title
                                                }
                                            >
                                                {" "}
                                                <FormattedMessage
                                                    id="dashboard__rateBreakdown"
                                                    defaultMessage="Rate Breakdown"
                                                />{" "}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            alignItems="flex-start"
                                            className={
                                                classes.rateBreakdown__container
                                            }
                                        >
                                            <RateBreakdown
                                                listView={false}
                                                charges={get(
                                                    shipment,
                                                    "rate.rateQuoteDetail.charges"
                                                )}
                                                usePreferredCurrency
                                                totalInPreferredCurrency={
                                                    usePreferredCurrency
                                                        ? get(
                                                              shipment,
                                                              "rate.rateQuoteDetail.totalInPreferredCurrency"
                                                          )
                                                        : get(
                                                              shipment,
                                                              "rate.rateQuoteDetail.total"
                                                          )
                                                }
                                                preferredCurrencyCode={get(
                                                    shipment,
                                                    "rate.preferredCurrencyCode"
                                                )}
                                                gridSize={12}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                    <Grid
                        container
                        justifyContent="space-between"
                        direction="row"
                    >
                        <Grid item>
                            {shipment.isArchived ? (
                                <Button
                                    className={classes.actionButton}
                                    id={`dashboardShipmentItem__${index}__unarchive`}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        changeArchiveStatus(
                                            shipmentId,
                                            false,
                                            viewArchived,
                                            openSnackbar,
                                            getSelectedTiles(),
                                            setTileCount
                                        )
                                        trackGA(
                                            GACategory,
                                            "Unarchive Button Click"
                                        )
                                    }}
                                >
                                    <FormattedMessage
                                        id="generalTerms__Unarchive"
                                        defaultMessage="Unarchive"
                                    />
                                </Button>
                            ) : (
                                <Button
                                    className={classes.actionButton}
                                    id={`dashboardShipmentItem__${index}__archive`}
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    onClick={() => {
                                        changeArchiveStatus(
                                            shipmentId,
                                            true,
                                            viewArchived,
                                            openSnackbar,
                                            getSelectedTiles(),
                                            setTileCount
                                        )
                                        trackGA(
                                            GACategory,
                                            "Archive Button Click"
                                        )
                                    }}
                                >
                                    <FormattedMessage
                                        id="generalTerms__Archive"
                                        defaultMessage="Archive"
                                    />
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            {["DISPATCHED"].includes(
                                shipmentStatusIdentifier
                            ) &&
                                canCancelShipment &&
                                get(status, "currentStatus.code") !==
                                    "CANCELED" && (
                                    <Fragment>
                                        <CancelPickupDialog
                                            pickupCancel={pickupCancel}
                                            handleNo={
                                                handleCancelShipmentDialogClose
                                            }
                                            handleYes={async () => {
                                                setLoading(true)
                                                const result = await onCancelShipment(
                                                    shipment,
                                                    identifiers
                                                )
                                                result.name === "ResponseError"
                                                    ? handleSnackbarOpen(
                                                          "error",
                                                          result
                                                      )
                                                    : handleSnackbarOpen(
                                                          "success",
                                                          identifiers
                                                      )

                                                if (
                                                    result.name !==
                                                    "ResponseError"
                                                ) {
                                                    await fetchElements(
                                                        page * pageSize,
                                                        true
                                                    )
                                                }
                                                setLoading(false)
                                                handleCancelShipmentDialogClose()
                                            }}
                                            shipmentsToCancel={
                                                shipmentsToCancel
                                            }
                                            open={
                                                state.openCancelShipmentDialog
                                            }
                                        />

                                        <Button
                                            className={classes.actionButton}
                                            id={`dashboardShipmentItem__${index}__cancel`}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={() => {
                                                handleCancelShipmentDialogOpen(
                                                    identifiers?.internalTrackingNumber
                                                )
                                                trackGA(
                                                    GACategory,
                                                    "Cancel Button Click"
                                                )
                                            }}
                                        >
                                            <FormattedMessage
                                                id="dashboard.shipment__cancel"
                                                defaultMessage="Cancel Pickup"
                                            />
                                        </Button>
                                    </Fragment>
                                )}
                            {eligibleForReturn && (
                                <Button
                                    className={classes.actionButton}
                                    id={`dashboardShipmentItem__${index}__return`}
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    onClick={() => {
                                        handleReturnShipment()
                                        trackGA(
                                            GACategory,
                                            "Return Button Click"
                                        )
                                    }}
                                >
                                    <FormattedMessage
                                        id="dashboard.shipment__return"
                                        defaultMessage="Return"
                                    />
                                </Button>
                            )}

                            {canShip && !isFreightDirectReturns && (
                                <Button
                                    className={classes.actionButton}
                                    id={`dashboardShipmentItem__${index}__shipAgain`}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        onShipAgain({
                                            shipmentId,
                                            queryVersion,
                                            openSnackbar,
                                        })
                                        trackGA(
                                            GACategory,
                                            "SHIP AGAIN Button Click"
                                        )
                                    }}
                                >
                                    <FormattedMessage
                                        id="dashboard.shipment__shipAgain"
                                        defaultMessage="Ship Again"
                                    />
                                </Button>
                            )}
                            <Button
                                className={classes.actionButton}
                                id={`dashboardShipmentItem__${index}__rates`}
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => {
                                    onViewQuote(
                                        shipmentId,
                                        isQuickQuote,
                                        isQuickRate,
                                        queryVersion
                                    )
                                    trackGA(GACategory, "RATES Button Click")
                                }}
                            >
                                <FormattedMessage
                                    id="documents__rates"
                                    defaultMessage="Rates"
                                />
                            </Button>
                            <Button
                                className={classes.actionButton}
                                id={`dashboardShipmentItem__${index}__orderDetails`}
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={onClickDetails}
                            >
                                <FormattedMessage
                                    id="dashboard.shipment__orderDetails"
                                    defaultMessage="Shipment Details"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </ExpansionPanelActions>
            </ExpansionPanel>
        </div>
    )
}

const activeAlertsSelector = createCachedSelector(
    (state, { shipmentId }) => state.shipment.list[shipmentId].alerts,
    alerts =>
        get(alerts, "events", []).filter(
            event => !event.dismissed && event.type === "warning"
        )
)((state, { shipmentId }) => `${shipmentId}`)

const canShipSelector = createCachedSelector(
    (state, { shipmentId }) =>
        get(state, `shipment.list[${shipmentId}].query.cpg`),
    state => state.user.profile.locations,
    (cpgCode, locations) => {
        if (!locations || !cpgCode) return null
        const location = locations.find(loc => loc.cpgCode === cpgCode)
        return get(
            location,
            "users[0].permissions.createShipments.value",
            false
        )
    }
)((state, { shipmentId }) =>
    get(state, `shipment.list[${shipmentId}].query.cpg`, "")
)

const carrierSelector = createSelector(
    (state, { shipmentId }) =>
        get(state, `shipment.list[${shipmentId}].shipment.carrier`),
    state => state.carriers.carriers,
    (carrierCode, carrierList = {}) => carrierList[carrierCode]
)

const mapStateToProps = createCachedSelector(
    state =>
        state?.dashboard?.dashboardFilters?.archiveFilter
            ?.viewArchivedShipments,
    (state, props) =>
        accessorialsSelector(state, {
            ...props,
            isPickup: true,
            includeCalculated: true,
        }),
    accessorialsSelector,
    canShipSelector,
    carrierSelector,
    statusStepSelector,
    activeAlertsSelector,
    (state, props) =>
        get(state, "user.profile.locations").find(
            loc => loc.cpgCode === get(props, "shipmentCPG")
        ),
    state => state?.user?.preferences?.hasFXFDReturnsLocation,
    (
        viewArchived,
        pickupAccessorials,
        accessorials,
        canShip,
        carrier,
        steps,
        activeAlerts,
        shipmentLocation,
        hasFXFDReturnsLocation
    ) => {
        const { activeIndex, statusSteps = [] } = steps || {}
        const currentStatus =
            activeIndex >= 0 ? statusSteps[activeIndex] : undefined

        return {
            viewArchived,
            accessorials: {
                pickup: pickupAccessorials,
                delivery: accessorials,
            },
            canShip,
            carrier,
            currentStatus,
            activeAlerts,
            canViewRateAmounts: get(
                shipmentLocation,
                "users[0].permissions.viewRateAmounts.value"
            ),
            shipmentLocation,
            hasFXFDReturnsLocation,
        }
    }
)((state, { shipmentId }) => `${shipmentId}`)

const mapDispatchToProps = dispatch => ({
    changePath: path => dispatch(changePath(path)),
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
    dismissAlert: (shipmentId, alertId) =>
        dispatch(dismissAlert(shipmentId, alertId)),
    changeArchiveStatus: (
        shipmentId,
        archive,
        viewArchived,
        openSnackbar,
        selectedTiles,
        setTileCount
    ) =>
        dispatch(
            changeArchiveStatus(
                shipmentId,
                archive,
                viewArchived,
                openSnackbar,
                selectedTiles,
                setTileCount
            )
        ),
    changeStarredStatus: (
        shipmentId,
        isFavorite,
        starredFilter,
        openSnackbar,
        changeTileCount,
        revertChangeTileCount
    ) =>
        dispatch(
            changeStarredStatus(
                shipmentId,
                isFavorite,
                starredFilter,
                openSnackbar,
                changeTileCount,
                revertChangeTileCount
            )
        ),
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DashboardShipmentItem))
)
