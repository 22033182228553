import React, { useState } from "react"
import { Router, Switch, Route } from "react-router-dom"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import QuotesPage from "./components/quotesPage"
import BookShipment from "./components/redesign/bookShipment"
import { connect } from "react-redux"
import moment from "moment"
import "moment/locale/fr-ca"
import "moment/locale/es"
import { SignUpForm, UserPreferences } from "./components/user"
import Locations from "./components/locations"
import CarrierOnboard from "./components/carrierOnboard"
import AddLocation from "./components/locations/addLocation"
import { TrackContainer } from "./components/track"
import { DashboardContainer } from "./components/dashboard"
import { AuthorizationContainer } from "./components/authorization"

import {
    SignupRoute,
    PrivateRoute,
    CatchAllRoute,
    LandingRoute,
} from "./components/route"
import {
    DeploymentReloadContainer,
    InactivityTimer,
    Modal,
    FooterRow,
} from "./components/util"
import { NavAppBar } from "./components/navigation"
import { AddressBook } from "./components/address"
import { IntercomContainer } from "./components/chat/intercom"
import ItemPage from "./components/item"
import { Statistics } from "./components/statistics"
import UnsavedChanges from "./components/locations/components/unsavedChanges"
import { TermsAndConditionsPanel } from "./components/util/terms-and-conditions"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
import { IntlProvider } from "react-intl"
import messages_frca from "./translations/fr-ca.json"
import messages_esmx from "./translations/es-mx.json"
import messages_enus from "./translations/en-us.json"
import { AttachmentsProvider } from "./context/providers/attachmentsProvider"
import { AttachmentsModalsWrapper } from "./context/components/attachmentModalsWrapper"
import { BookedShipmentProvider } from "./context/providers/BookedShipmentProvider"
import { BookedShipmentModalWrapper } from "./context/components/BookedShipmentModalWrapper"
import { SnackbarProvider } from "./context/providers/snackbarProvider"
import SnackbarWrapper from "./context/components/snackbar"
import MandatoryPrompt from "./components/mandatoryPrompt/MandatoryPrompt"
import { DocumentContainer } from "./components/documents/Index"
import history from "./actions/history"
import QuickRate from "./components/redesign/quickRate"
import { CustomsProfileProvider } from "./context/providers/CustomsProfileProvider"
import { TileCountProvider } from "./context/providers/TileCountProvider"
import OrdersPage from "./components/orders"
import TrailerManifestPage from "./components/trailerManifest"
import OrdersProvider from "./context/providers/OrdersProvider"
import AssemblyAdmin from "./components/admin/views/AssemblyAdmin"
import USDimWeightAdmin from "./components/admin/views/USDimWeightAdmin"
import { useFlags } from "launchdarkly-react-client-sdk"
import OffshoreGatewayAdmin from "./components/admin/views/OffshoreGatewayAdmin"
import ContactsPage from "./components/contacts"
import GAProvider from "./context/providers/GoogleAnalyticsProvider"
import ErrorRedirectScreen from "./components/errors/ErrorRedirectScreen"
import ItemsProvider from "./context/providers/ItemsProvider"
import InnerErrorBoundary from "./components/errors/InnerErrorBoundary"
import Internationalization from "./components/admin/Internationalization"
import { MultiAttachmentsProvider } from "./context/providers/multiAttachmentsProvider"
import { MultiAttachmentsModalsWrapper } from "./context/components/multiAttachmentModalsWrapper"
import UsersProvider from "./context/providers/UserProvider"

export const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#4d148c",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            // light: '#0066ff',
            main: "#ff6200",
            light: "#ffd178",
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },

        // error: will use the default color
    },
    typography: {
        letterSpacing: "0.0em",
    },

    overrides: {
        MuiTypography: {
            root: {
                letterSpacing: "0.0em",
            },
            body1: {
                fontFamily: "Roboto",
                fontSize: "0.875rem",
                lineHeight: 1.43,
                letterSpacing: "0.0em",
            },
            body2: {
                fontFamily: "Roboto",
                letterSpacing: "0.0em",
            },
            caption: {
                fontFamily: "Roboto",
                letterSpacing: "0.0em",
                color: "rgba(0, 0, 0, 0.64)",
                lineHeight: "1.375em",
            },
            subtitle1: {
                fontFamily: "Roboto",
                letterSpacing: "0.0em",
            },
            subtitle2: {
                fontFamily: "Roboto",
                letterSpacing: "0.0em",
            },
            h4: {
                fontFamily: "Roboto",
                letterSpacing: "0.0em",
            },
            h5: {
                fontFamily: "Roboto",
                letterSpacing: "0.0em",
            },
            h6: {
                fontFamily: "Roboto",
                letterSpacing: "0.0em",
            },
            button: {
                fontFamily: "Roboto",
                letterSpacing: "0.0em",
            },
        },
        MuiTableCell: {
            root: {
                padding: "4px 8px",
            },
        },
        MuiCollapse: {
            entered: {
                height: "auto",
                overflow: "visible",
            },
        },
        MuiButton: {
            root: {
                fontWeight: "700",
                size: "medium",
                letterSpacing: "0.0em",
            },
        },
        MuiFab: {
            root: {
                fontWeight: "700",
                size: "medium",
                letterSpacing: "0.0em",
            },
        },
        MuiFormLabel: {
            root: {
                color: "#636363",
            },
        },
        MuiListItemText: {
            root: { color: "rgba(0, 0, 0, 0.87)", padding: "0 10px 0" },
        },
    },
})

const AppComponent = ({ language }) => {
    const [state, setState] = useState({
        unsavedChangesOpen: false,
        callback: null,
        message: null,
        LPLanguage: localStorage.getItem("language") || "en-us",
    })
    const { createTrailerManifest, contactsPageRedesign } = useFlags()

    const getUserConfirmation = (message, callback) => {
        callback(false)
        setState(prevState => ({
            ...prevState,
            unsavedChangesOpen: true,
            message,
            callback,
        }))
    }

    const handleClose = () => {
        setState(prevState => ({
            ...prevState,
            unsavedChangesOpen: false,
            message: null,
            callback: null,
        }))
    }

    const handleContinue = () => {
        state.callback(true)
        handleClose()
    }

    const setLanguage = LPLanguage => {
        setState(prevState => ({ ...prevState, LPLanguage }))
    }

    const { unsavedChangesOpen, message, LPLanguage } = state
    const landingPageLanguage = localStorage.getItem("language")
    const adjustedLanguage = language || landingPageLanguage || "en-us"

    moment.locale(
        adjustedLanguage === "en-us"
            ? "en"
            : adjustedLanguage === "es-mx"
            ? "es"
            : adjustedLanguage
    )

    const messages = {
        "fr-ca": messages_frca,
        "es-mx": messages_esmx,
        "en-us": messages_enus,
    }

    return (
        <IntlProvider
            locale={adjustedLanguage}
            messages={messages[adjustedLanguage]}
        >
            <GAProvider>
                <SnackbarProvider>
                    <UsersProvider>
                        <OrdersProvider>
                            <AttachmentsProvider>
                                <MultiAttachmentsProvider>
                                    <CustomsProfileProvider>
                                        <BookedShipmentProvider>
                                            <ItemsProvider>
                                                <TileCountProvider>
                                                    <UnsavedChanges
                                                        message={message}
                                                        handleClose={
                                                            handleClose
                                                        }
                                                        handleContinue={() =>
                                                            handleContinue()
                                                        }
                                                        open={
                                                            unsavedChangesOpen
                                                        }
                                                    />
                                                    <Router
                                                        basename="/"
                                                        history={history}
                                                        getUserConfirmation={
                                                            getUserConfirmation
                                                        }
                                                    >
                                                        <div
                                                            id="app"
                                                            style={{
                                                                backgroundColor:
                                                                    "#F2F2F2",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <MuiThemeProvider
                                                                theme={theme}
                                                            >
                                                                <AuthorizationContainer
                                                                    setLanguage={
                                                                        setLanguage
                                                                    }
                                                                    language={
                                                                        LPLanguage
                                                                    }
                                                                />
                                                                <DeploymentReloadContainer />
                                                                <InactivityTimer />
                                                                <IntercomContainer
                                                                    appID="gvf3zf71"
                                                                    vertical_padding={
                                                                        80
                                                                    }
                                                                />
                                                                <MuiPickersUtilsProvider
                                                                    utils={
                                                                        MomentUtils
                                                                    }
                                                                >
                                                                    <SnackbarWrapper />
                                                                    <AttachmentsModalsWrapper />
                                                                    <MultiAttachmentsModalsWrapper />
                                                                    <BookedShipmentModalWrapper />
                                                                    <MandatoryPrompt />
                                                                    <NavAppBar />
                                                                    <InnerErrorBoundary>
                                                                        <main
                                                                            style={{
                                                                                paddingTop:
                                                                                    "64px",
                                                                                flex:
                                                                                    "1",
                                                                                backgroundColor:
                                                                                    "rgb(242, 242, 242)",
                                                                            }}
                                                                        >
                                                                            <Switch>
                                                                                <LandingRoute
                                                                                    exact
                                                                                    path="/"
                                                                                />
                                                                                <SignupRoute
                                                                                    exact
                                                                                    path="/user/signup"
                                                                                    component={
                                                                                        SignUpForm
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    exact
                                                                                    path="/terms/:lang?"
                                                                                    render={props => (
                                                                                        <TermsAndConditionsPanel
                                                                                            {...props}
                                                                                            omitButtons
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/home"
                                                                                    component={
                                                                                        DashboardContainer
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/rate/:shipmentId?/:mode?"
                                                                                    component={
                                                                                        QuotesPage
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    component={
                                                                                        QuotesPage
                                                                                    }
                                                                                    path="/rate/:shipmentId/:shipMode/:direction/:carrierCode/:alternateQuote?/book"
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    component={
                                                                                        BookShipment
                                                                                    }
                                                                                    path="/book"
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/book/:shipmentId?/:mode?"
                                                                                    component={
                                                                                        BookShipment
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    component={
                                                                                        BookShipment
                                                                                    }
                                                                                    path="/book/:shipmentId?"
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    component={
                                                                                        QuickRate
                                                                                    }
                                                                                    path="/quickRate"
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    component={
                                                                                        QuickRate
                                                                                    }
                                                                                    path="/quickRate/:shipmentId?"
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/track/:shipmentId?"
                                                                                    component={
                                                                                        TrackContainer
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/user"
                                                                                    component={
                                                                                        UserPreferences
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/locations"
                                                                                    component={
                                                                                        Locations
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/locations/add"
                                                                                    component={
                                                                                        AddLocation
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/error"
                                                                                    component={
                                                                                        ErrorRedirectScreen
                                                                                    }
                                                                                />
                                                                                {contactsPageRedesign ? (
                                                                                    <PrivateRoute
                                                                                        exact
                                                                                        path="/address/:contactId?"
                                                                                        language={
                                                                                            state.LPLanguage
                                                                                        }
                                                                                        component={
                                                                                            ContactsPage
                                                                                        }
                                                                                    />
                                                                                ) : null}
                                                                                {!contactsPageRedesign ? (
                                                                                    <PrivateRoute
                                                                                        exact
                                                                                        path="/address/:contactId?"
                                                                                        component={
                                                                                            AddressBook
                                                                                        }
                                                                                    />
                                                                                ) : null}
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/items"
                                                                                    component={
                                                                                        ItemPage
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/orders"
                                                                                    component={
                                                                                        OrdersPage
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/statistics"
                                                                                    component={
                                                                                        Statistics
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path={
                                                                                        "/admin/assembly"
                                                                                    }
                                                                                    component={
                                                                                        AssemblyAdmin
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path={
                                                                                        "/admin/usdimpricing"
                                                                                    }
                                                                                    component={
                                                                                        USDimWeightAdmin
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path={
                                                                                        "/admin/offshoregateway"
                                                                                    }
                                                                                    component={
                                                                                        OffshoreGatewayAdmin
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path={
                                                                                        "/admin/i18n"
                                                                                    }
                                                                                    component={
                                                                                        Internationalization
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path={
                                                                                        "/track/:shipmentId/document/:documentType"
                                                                                    }
                                                                                    component={
                                                                                        DocumentContainer
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path={
                                                                                        "/carrier/:mode"
                                                                                    }
                                                                                    component={
                                                                                        CarrierOnboard
                                                                                    }
                                                                                />
                                                                                <PrivateRoute
                                                                                    exact
                                                                                    path="/carrier/:mode/:carrierId"
                                                                                    component={
                                                                                        CarrierOnboard
                                                                                    }
                                                                                />
                                                                                {createTrailerManifest && (
                                                                                    <PrivateRoute
                                                                                        exact
                                                                                        path={
                                                                                            "/trailerManifest"
                                                                                        }
                                                                                        component={
                                                                                            TrailerManifestPage
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                ,
                                                                                <CatchAllRoute />
                                                                            </Switch>
                                                                            <Modal />
                                                                        </main>
                                                                    </InnerErrorBoundary>
                                                                </MuiPickersUtilsProvider>
                                                            </MuiThemeProvider>
                                                            <div
                                                                id="footer"
                                                                style={{
                                                                    backgroundColor:
                                                                        "#F2F2F2",
                                                                }}
                                                            >
                                                                <FooterRow />
                                                            </div>
                                                        </div>
                                                    </Router>
                                                </TileCountProvider>
                                            </ItemsProvider>
                                        </BookedShipmentProvider>
                                    </CustomsProfileProvider>
                                </MultiAttachmentsProvider>
                            </AttachmentsProvider>
                        </OrdersProvider>
                    </UsersProvider>
                </SnackbarProvider>
            </GAProvider>
        </IntlProvider>
    )
}

const mapStateToProps = state => {
    return {
        language:
            state?.form?.signup?.values?.preferences?.language ||
            state?.user?.profile?.preferences?.language,
    }
}

export const App = connect(mapStateToProps)(AppComponent)
