import React from "react"
import PropTypes from "prop-types"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { get } from "lodash"
import { FormattedMessage } from "react-intl"
import { useFlags } from "launchdarkly-react-client-sdk"
import { Box } from "@material-ui/core"

const CarrierCell = ({ carrier, onCarrierSelect }) => {
    const { dashboardShipperLogo } = useFlags()

    return (
        <Paper
            style={{
                margin: "10px",
                borderRadius: "10px",
                width: "250px",
                padding: "10px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {dashboardShipperLogo ? (
                    <img
                        style={{
                            maxWidth: "200px",
                            maxHeight: "50px",
                            margin: "10px",
                        }}
                        src={carrier?.carrierDetails?.logo}
                        alt={`${carrier?.name}`}
                    />
                ) : null}
                <Typography
                    style={{ margin: "10px" }}
                    color="primary"
                    variant="subtitle1"
                >
                    {carrier?.name}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    id={`select-carrier-button-${get(
                        carrier,
                        "capacityProviderIdentifiers[0].value",
                        ""
                    )}`}
                    onClick={() => onCarrierSelect(carrier)}
                >
                    <FormattedMessage
                        id="addCarrier.table.select"
                        defaultMessage="Select"
                    />
                </Button>
            </Box>
        </Paper>
    )
}

CarrierCell.propTypes = {
    carrier: PropTypes.object.isRequired,
    onCarrierSelect: PropTypes.func.isRequired,
}

export default CarrierCell
