import React, { useState } from "react"
import { Grid, Button, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import RedirectP44Dialog from "./RedirectP44Dialog"

const Carriers = ({
    inHouseCarrierOnboarding,
    dialogOpen,
    location,
    owner,
    handleCarrierAdd,
    handleCarrierEdit,
    classes,
    handleClose,
    carrierList,
    userOnboardingDNA,
    setDialogOpen,
    gaCategory,
    trackGA,
}) => {
    const [carrierCode, setCarrierCode] = useState(null)
    const [carrierAccountId, setCarrierAccountId] = useState(null)

    const relevantCarriers =
        location?.carriers === null
            ? []
            : location?.carriers?.filter(it => it.carrier !== "FXFE") ?? []

    return (
        <Grid item container direction="column">
            <Grid
                item
                container
                justify="space-between"
                alignItems="center"
                alignContent="center"
                className={classes.location__card__header}
            >
                <Grid item container alignItems="center" xs={4}>
                    <Typography gutterBottom variant="h6">
                        <FormattedMessage
                            id="locations.carriers__title"
                            defaultMessage="Carriers"
                        />
                    </Typography>
                </Grid>
                {owner && (
                    <Grid
                        item
                        container
                        xs={8}
                        alignItems="center"
                        justify="flex-end"
                    >
                        <Button
                            onClick={() => {
                                trackGA(gaCategory, "Add Carrier Click")
                                if (inHouseCarrierOnboarding) {
                                    handleCarrierAdd()
                                } else {
                                    setDialogOpen(true)
                                }
                            }}
                            color="primary"
                            id="addCarrierButton"
                        >
                            <FormattedMessage
                                id="locations.carriers__addCarrier"
                                defaultMessage="Add Carrier"
                            />
                        </Button>
                    </Grid>
                )}
            </Grid>
            {relevantCarriers.map((carrier, index) =>
                owner ? (
                    <Grid
                        item
                        container
                        className={
                            index === relevantCarriers.length - 1
                                ? classes.location__row__last
                                : classes.location__row
                        }
                        alignItems="center"
                        key={index}
                        onClick={() => {
                            if (inHouseCarrierOnboarding) {
                                handleCarrierEdit(
                                    carrier.carrier,
                                    carrier.accountId
                                )
                            } else {
                                setDialogOpen(true)
                                setCarrierCode(carrier.carrier)
                                setCarrierAccountId(carrier.accountId)
                            }
                        }}
                    >
                        <Typography variant="body2" color="inherit">
                            {carrierList?.carriers[carrier?.carrier]?.name}
                        </Typography>
                    </Grid>
                ) : (
                    <Grid
                        item
                        container
                        className={
                            index === relevantCarriers.length - 1
                                ? classes.location__row__last__noHover
                                : classes.location__row__noHover
                        }
                        alignItems="center"
                        key={index}
                    >
                        <Typography variant="body2" color="inherit">
                            {carrierList?.carriers[carrier?.carrier]?.name}
                        </Typography>
                    </Grid>
                )
            )}
            <RedirectP44Dialog
                dialogOpen={dialogOpen}
                handleClose={handleClose}
                gaCategory={gaCategory}
                onContinue={() => {
                    handleCarrierEdit(carrierCode, carrierAccountId)
                    setCarrierCode(null)
                    setCarrierAccountId(null)
                    trackGA(
                        gaCategory,
                        "Redirect Notice Modal - Continue Click"
                    )
                }}
            />
        </Grid>
    )
}

export default Carriers
