/* eslint-disable react/prop-types */
import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { Typography } from "@material-ui/core"

const handleChange = (name, setFieldValue) => event => {
    setFieldValue(name, event.target.checked)
}

const FormikSwitch = ({
    field,
    field: { name, value },
    form: { touched, errors, setFieldValue },
    label,
    placeholder,
    ...props
}) => (
    // const error = get(errors, name)
    // const inErrorState = !!error && !!get(touched, name)

    <FormControlLabel
        style={{ padding: "10px 0" }}
        control={
            <Switch
                {...field}
                checked={value}
                value={"switch"}
                onChange={handleChange(name, setFieldValue)}
            />
        }
        label={<Typography>{label}</Typography>}
    />
)

export default FormikSwitch
