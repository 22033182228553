import { goFetch } from "../../../http"

export async function requestCarrierList(cpgCode) {
    // returns a list of carriers with already added carriers filtered out
    try {
        const { data } = await goFetch(
            `/carrier/onboarding/find/capacityProviderAccountsList?cpgCode=${cpgCode}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return data
    } catch (error) {
        throw error
    }
}

export async function requestCurrentCarriers(cpgCode) {
    try {
        const { data } = await goFetch(
            `/carrier/onboarding/find/cpgAccountByCode?cpgCode=${cpgCode}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return data
    } catch (error) {
        throw error
    }
}

export async function requestCarrierMetadata(carrierId) {
    try {
        const { data } = await goFetch(
            `/carrier/onboarding/find/cpgAccountMetadata?accountCode=${carrierId}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return data
    } catch (error) {
        throw error
    }
}

export async function requestCarrierData(accountId) {
    try {
        const { data } = await goFetch(
            `/carrier/onboarding/find/cpgAccountById?accountId=${accountId}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return data
    } catch (error) {
        throw error
    }
}

export async function requestBillToLocation(billToLocationId) {
    try {
        const billToLocation = await goFetch(
            `/carrier/onboarding/find/getBillToLocation?billToLocationId=${billToLocationId}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return billToLocation
    } catch (error) {
        throw error
    }
}

export async function requestCarrierOnboardingGuide(carrierId) {
    try {
        const { data } = await goFetch(
            `/carrier/onboarding/find/guide?cpgCode=${carrierId}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return data
    } catch (error) {
        throw error
    }
}

export async function updateCarrier(location, carrierId, values, accountId) {
    const payload = {
        accounts: [values],
        code: carrierId,
        capacityProviderIdentifier: {
            type: "SCAC",
            value: carrierId,
        },
        group: {
            code: location?.cpgCode,
            id: location?.cpgId,
            name: location?.billingContact?.companyName,
        },
        id: accountId,
    }
    try {
        const updateResult = await goFetch(
            `/carrier/onboarding/update/capacityProvider?accountId=${accountId}&cpgCode=${carrierId}`,
            {
                method: "PUT",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
                data: payload,
            },
            true
        )
        return updateResult
    } catch (error) {
        throw error
    }
}

export async function deleteCarrier(accountId, cpgCode) {
    try {
        const deleteResult = await goFetch(
            `/carrier/onboarding/capacityProvider/${accountId}/${cpgCode}`,
            {
                method: "DELETE",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return deleteResult
    } catch (error) {
        throw error
    }
}
