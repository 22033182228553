import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import CarrierCell from "./CarrierCell"
import { Box, InputAdornment, TextField } from "@material-ui/core"
import { Search } from "@material-ui/icons"

const AddCarrierDisplay = ({ carrierList, onCarrierSelect }) => {
    const [searchQuery, setSearchQuery] = useState("")

    const handleSearchChange = event => {
        setSearchQuery(event.target.value.toLowerCase())
    }

    const getFilteredCarrierList = () => {
        if (!searchQuery) return carrierList
        return carrierList.filter(carrier =>
            carrier.name.toLowerCase().includes(searchQuery)
        )
    }

    const filteredCarrierList = getFilteredCarrierList()

    return (
        <Box>
            <TextField
                style={{ marginBottom: "20px", marginTop: "20px" }}
                name="orders.searchOrder"
                autoFocus
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
                label={
                    <FormattedMessage
                        id="carrier.search"
                        defaultMessage="Search for a carrier"
                    />
                }
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                }}
            >
                {filteredCarrierList.length > 0 ? (
                    filteredCarrierList.map(carrier => (
                        <CarrierCell
                            onCarrierSelect={onCarrierSelect}
                            key={carrier.name}
                            carrier={carrier}
                        />
                    ))
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        <FormattedMessage
                            id="carrier.noResults"
                            defaultMessage="No carriers found"
                        />
                    </Typography>
                )}
            </Box>
        </Box>
    )
}

AddCarrierDisplay.propTypes = {
    carrierList: PropTypes.array.isRequired,
    onCarrierSelect: PropTypes.func.isRequired,
}

export default AddCarrierDisplay
