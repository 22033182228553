import moment from "moment-timezone"
import { get, union, uniqWith, isEqual } from "lodash"
import { SubmissionError } from "redux-form"
import { cleanupObject } from "../misc"
import { goFetch, goFetchV2 } from "../http"
import {
    changePath,
    errorMessage,
    typedError,
    USER_INPUT_ENDED_DELAY,
    createGAEvent,
} from "./index"
import { FormattedMessage } from "react-intl"
import { setEditMode } from "./quote"
import { pastDateValidator } from "./validation"
import { invalidateItemList } from "./item"
import { transformRates } from "./quote-transform"
import { noRateResults, rateRequestError } from "../messages/error-constants"
import { pickBy, cloneDeep } from "lodash"
import { saveContact } from "./contact"
import {
    loadBSQuoteResult,
    loadQuickQuoteResult,
} from "./book-shipment-requests"
import { trackGAEvent } from "./user"
import { shipmentLoadError } from "./track"
import FileSaver from "file-saver"
export const ARCHIVE_QUOTE = "ARCHIVE_QUOTE"
export const UNARCHIVE_QUOTE = "UNARCHIVE_QUOTE"
export const REMOVE_QUOTE = "REMOVE_QUOTE"
export const BACKFILL_QUOTE_LIST = "BACKFILL_QUOTE_LIST"
export const QUOTE_LOAD = "QUOTE_LOAD"
export const QUOTE_FIELD_VALIDATION = "QUOTE_FIELD_VALIDATION"
export const QUOTE_RESULT = "QUOTE_RESULT"
export const QUOTE_ADD_LIST_ITEM = "QUOTE_ADD_LIST_ITEM"
export const QUOTE_LOAD_ERROR = "QUOTE_LOAD_ERROR"
export const QUOTE_LIST_RESULT = "QUOTE_LIST_RESULT"
export const QUOTE_METADATA_START = "QUOTE_METADATA_START"
export const QUOTE_METADATA_RESULT = "QUOTE_METADATA_RESULT"
export const QUOTE_LIST_ERROR = "QUOTE_LIST_ERROR"
export const QUOTE_LIST_LOAD = "QUOTE_LIST_LOAD"
export const CLOSE_RATES_ERRORS = "CLOSE_RATES_ERRORS"
export const QUOTE_EXPIRED = "QUOTE_EXPIRED"
export const MAP_FORM_TO_SEARCH = "MAP_FORM_TO_SEARCH"
export const QUOTE_SEARCH_RESULT = "QUOTE_SEARCH_RESULT"
export const QUEUE_QUOTE_SEARCH = "QUEUE_QUOTE_SEARCH"
export const QUOTE_LIST_RESULT_FILTER_APPLIED =
    "QUOTE_LIST_RESULT_FILTER_APPLIED"

export function removeQuoteAndBackFillList(quoteId) {
    return async (dispatch, getState) => {
        dispatch({ type: REMOVE_QUOTE, quoteId })
        try {
            const numberOfRequestedQuotes =
                getState().quotes?.pagination?.page *
                getState().quotes?.pagination?.pageSize
            if (
                getState().quotes?.list?.totalCount > numberOfRequestedQuotes &&
                Object.keys(getState().quotes?.list?.items).length <
                    numberOfRequestedQuotes
            ) {
                return dispatch(
                    fetchQuoteListAndCount(
                        numberOfRequestedQuotes,
                        getState().form?.dashboardFilters?.values ?? {},
                        getState().dashboard?.activeDashboardTile,
                        true
                    )
                )
            }
        } catch (error) {
            return dispatch(shipmentLoadError(error))
        }
    }
}

export const changeQuoteArchiveStatus = (
    quoteId,
    archive,
    archiveFilterOn,
    openSnackbar
) => {
    return async (dispatch, getState) => {
        try {
            const res = await goFetch(
                `/quotes/archive`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        archive: archive,
                        quotesToArchive: [quoteId],
                    },
                },
                true
            )

            if (archive) {
                dispatch(
                    createGAEvent(
                        "Archive Quote",
                        "Quote archived from dashboard"
                    )
                )
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="dashboard.quote.archiveSuccess"
                        defaultMessage="Quote Archived"
                    />
                )
                if (archiveFilterOn) {
                    return dispatch({ type: ARCHIVE_QUOTE, quoteId })
                } else {
                    return dispatch(removeQuoteAndBackFillList(quoteId))
                }
            } else {
                dispatch(
                    createGAEvent(
                        "Archive Quote",
                        "Quote unarchived from dashboard"
                    )
                )
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="dashboard.quote.unarchiveSuccess"
                        defaultMessage="Quote Unarchived"
                    />
                )
                return dispatch({ type: UNARCHIVE_QUOTE, quoteId })
            }
        } catch (error) {
            if (archive) {
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="dashboard.quote.archiveError"
                        defaultMessage="Unable to archive quote"
                    />
                )
            } else {
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="dashboard.quote.unarchiveError"
                        defaultMessage="Unable to unarchive quote"
                    />
                )
            }
        }
    }
}

export const quoteLoadError = error => typedError(QUOTE_LOAD_ERROR, error)

export function addQuoteListItem(quote) {
    return { type: QUOTE_ADD_LIST_ITEM, quote }
}

function mapFormToSearch(quoteForm, accessorialsForm) {
    const newQuoteForm = cloneDeep(quoteForm)
    const newAccessorialsForm = cloneDeep(accessorialsForm)
    const formattedAccessorialsForm = createAccessorialsPayload(
        newAccessorialsForm
    )
    newQuoteForm.items = pickBy(newQuoteForm.items, item => item)

    return {
        type: MAP_FORM_TO_SEARCH,
        newQuoteForm,
        formattedAccessorialsForm,
    }
}

function shouldRequestQuotes(state) {
    return (
        !state.isFetching &&
        (!state.isLoaded || Object.keys(state?.items).length < state.totalCount)
    )
}

const excludedRateErrors = [
    "503 Service Unavailable",
    "Destination and/or Origin are out of service area.",
]

export function filterRatesError(rates, error) {
    if (!error) return []
    const availableRates = rates?.map(x => `${x.carrierCode}|${x.mode}`)
    const errorsByCarrier = error?.reduce((prev, current) => {
        const key = `${current.carrierCode}|${current.mode}`
        if (availableRates.includes(key)) {
            return prev
        }
        prev[key] = [...(prev[key] ?? []), current]
        return prev
    }, {})

    //LS-3385
    const customExtremeFedexWarning = error.filter(
        e => e?.carrierCode == "FXFE" && e?.mode == "LTL"
    )
    if (
        availableRates.includes("FXFE|LTL") &&
        customExtremeFedexWarning.length > 0
    ) {
        errorsByCarrier["FXFE|LTL"] = customExtremeFedexWarning
    }
    return Object.values(errorsByCarrier)
        .map(carrierErrors => ({
            ...carrierErrors[0],
            errorMessages: uniqWith(
                carrierErrors?.reduce(
                    (prev, y) => [...prev, ...y.errorMessages],
                    []
                ),
                isEqual
            ),
        }))
        .map(e => ({
            ...e,
            errorMessages: (e?.errorMessages ?? []).filter(
                msg =>
                    !excludedRateErrors.includes(msg.diagnostic || msg.message)
            ),
        }))
        .filter(e => e?.errorMessages?.length)
}

export function closeRatesError(carrierCode) {
    return { type: CLOSE_RATES_ERRORS, carrierCode }
}

export function loadQuoteResult(quote, shouldChangePath = true) {
    return dispatch => {
        const isQuickQuote = get(quote, "search.isQuickQuote")
        const basePath = isQuickQuote ? "/qrate" : "/rate"
        dispatch(addQuoteListItem(quote))
        if (!shouldChangePath) return
        dispatch(setEditMode(false))
        const shipmentId = get(quote, "identifiers.internalTrackingNumber")
        const path = shipmentId ? `${basePath}/${shipmentId}` : `${basePath}`
        dispatch(changePath(path))
    }
}

export function updateQuoteResult(quote, checkExpiry = true) {
    return async (dispatch, getState) => {
        const result = { ...quote.result }
        result.ratesError = filterRatesError(
            result.rateQuotes,
            result.ratesError
        )
        result.rateQuotes = transformRates(getState(), quote)
        const newQuote = { ...quote, result }
        let isExpired = checkExpiry
        const originTimezone =
            get(quote, "search.originTimezone") || "Pacific/Honolulu"
        if (checkExpiry && originTimezone) {
            isExpired = moment
                .tz(newQuote.updated_at, originTimezone)
                .isBefore(moment().tz(originTimezone), "day")
        }
        dispatch({ type: QUOTE_RESULT, ...newQuote })
        if (checkExpiry) dispatch({ type: QUOTE_EXPIRED, isExpired })
    }
}

export function quoteLoad() {
    return { type: QUOTE_LOAD }
}

export function fieldValidation(validation, strict) {
    return { type: QUOTE_FIELD_VALIDATION, validation, strict }
}

const dockAccessorials = ["DOCKPU", "DOCKDEL"]

const createPayload = ({
    referenceNumber,
    volumeLTL,
    handlingUnits,
    isFreightDirect,
    isFreightDirectReturns,
    ...search
}) => {
    const adjustedHandlingUnits = handlingUnits.map(hu => {
        const adjustedHu = { ...hu }

        if (!isFreightDirect && !isFreightDirectReturns) {
            adjustedHu.items = adjustedHu.items.map(
                ({ freightDirectPieces, ...rest }) => rest
            )
        }

        if (hu.isMultiClass) {
            adjustedHu.isIndividualHUWeight = true
        }

        return adjustedHu
    })

    return {
        ...cleanupObject(search),
        volumeType: volumeLTL ? "VOLUME_ONLY" : "NORMAL_AND_VOLUME",
        pickupAccessorials: isFreightDirect
            ? []
            : search.pickupAccessorials.filter(
                  item => !dockAccessorials.includes(item.value)
              ),
        deliveryAccessorials: isFreightDirect
            ? []
            : search.deliveryAccessorials.filter(
                  item => !dockAccessorials.includes(item.value)
              ),
        pickupDate: moment(search.pickupDate).format("YYYY-MM-DD"),
        identifiers: { referenceNumber },
        handlingUnits: adjustedHandlingUnits,
    }
}

const createAccessorialsPayload = values => {
    let pickupAccessorials = get(values, "pickupAccessorials", [])
    let deliveryAccessorials = get(values, "deliveryAccessorials", [])
    if (typeof pickupAccessorials === "string") {
        pickupAccessorials = pickupAccessorials.split(",")
    }

    return {
        pickupAccessorials: pickupAccessorials
            .filter(item => item && !dockAccessorials.includes(item))
            .map(i => ({ value: i })),
        deliveryAccessorials: deliveryAccessorials
            .filter(item => item && !dockAccessorials.includes(item))
            .map(i => ({ value: i })),
        commodityAccessorials: get(values, "protectFromFreezing")
            ? [{ value: "PFZ" }]
            : [],
    }
}

export function copyShipment({
    shipmentId,
    queryVersion,
    shipAgain,
    openSnackbar,
}) {
    return async (dispatch, getState) => {
        dispatch(quoteLoad())
        let quotes

        if (queryVersion === "V2") {
            try {
                const response = await goFetchV2(
                    `/quotes/${shipmentId}`,
                    {
                        method: "POST",
                        credentials: "same-origin",
                        headers: { "cache-control": "no-cache" },
                        params: {
                            keepOrderReference: !shipAgain,
                        },
                    },
                    true
                )

                let adjustedRate = { ...response }

                adjustedRate.data.result.ratesError = filterRatesError(
                    adjustedRate?.result?.rateQuotes,
                    adjustedRate?.result?.ratesError
                )

                if (adjustedRate?.data?.search?.isQuickRate) {
                    return dispatch(loadQuickQuoteResult(adjustedRate.data))
                } else {
                    return dispatch(loadBSQuoteResult(adjustedRate.data))
                }
            } catch (error) {
                if (openSnackbar) {
                    const restrictions = ["Over-length", "Extreme-length"]
                    restrictions.forEach(restriction => {
                        if (
                            (error?.response?.message ?? "").includes(
                                restriction
                            )
                        ) {
                            openSnackbar(
                                "error",
                                <FormattedMessage
                                    id="dashboard.shipagain.overlengthRestriction"
                                    defaultMessage="{Restriction} shipments are not allowed on this location"
                                    values={{ Restriction: restriction }}
                                />
                            )
                        }
                    })
                }
                return error
            }
        } else {
            try {
                const { data } = await goFetch(
                    `/quotes/${shipmentId}`,
                    {
                        method: "POST",
                        credentials: "same-origin",
                        headers: { "cache-control": "no-cache" },
                    },
                    true
                )
                quotes = data
            } catch (error) {
                return dispatch(errorMessage(error))
            }
            if (!get(quotes, "result.rateQuotes")) {
                return dispatch(errorMessage(new Error(noRateResults)))
            }
            return dispatch(loadQuoteResult(quotes))
        }
    }
}

export function fetchAllRates(query, shipmentId) {
    return async (dispatch, getState) => {
        dispatch(quoteLoad())
        let quotes
        try {
            const { data } = await goFetch(
                `/quotes/${shipmentId}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: createPayload({
                        ...query,
                        volumeLTL: false,
                    }),
                },
                true
            )
            quotes = data
        } catch (error) {
            return dispatch(errorMessage(error))
        }
        if (!get(quotes, "result.rateQuotes")) {
            return dispatch(errorMessage(new Error(noRateResults)))
        }
        dispatch(loadQuoteResult(quotes))
        dispatch(updateQuoteResult(quotes, false))
    }
}

export function updateRateResiCheck() {
    return async (dispatch, getState) => {
        const state = getState()

        const selectedResiAccessorials =
            state?.form?.bolEntry?.values?.destination
                ?.selectedResiAccessorials ?? ""
        const updatedAddress =
            state?.form?.bolEntry?.values?.destination?.address
        const contactInfo = state?.form?.bolEntry?.values?.destination?.contact
        const handlingUnits = state?.form?.bolEntry?.values?.handlingUnits
        const search = state?.form?.bolEntry?.values?.search

        let newAddressId
        let newContactId

        try {
            const result = await dispatch(
                saveContact({
                    ...updatedAddress,
                    ...contactInfo,
                    mail: contactInfo?.email,
                    companyName: updatedAddress?.name,
                })
            )
            newAddressId = result?.address?._id
            newContactId = result?.contact?._id
        } catch (error) {
            console.log("error creating contact", error)
        }

        const payload = {
            ...search,
            handlingUnits,
            deliveryAccessorials: selectedResiAccessorials
                .split(",")
                .map(it => {
                    return { value: it }
                }),
            destinationAddress: newAddressId,
            destinationContact: newContactId,
            originAddress: search?.originAddress?._id,
        }

        dispatch(quoteLoad())
        const url = "/quotes"
        let quotes
        try {
            const { data } = await goFetch(
                url,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: createPayload(payload),
                },
                true
            )
            quotes = data
        } catch (err) {
            throw rateRequestError
        }
        if (!get(quotes, "result.rateQuotes"))
            throw new SubmissionError({ _error: noRateResults })
        dispatch(invalidateItemList())
        dispatch(loadQuoteResult(quotes))
    }
}

const intraMexicoFreightClassCheck = values => {
    let newValues = { ...values }
    if (
        newValues?.destinationCountry === "MX" &&
        newValues?.originCountry === "MX"
    ) {
        newValues.handlingUnits.forEach(hu => {
            hu.items.forEach(item => {
                if (!item?.freightClass) {
                    //default to mexico, the freight class does not change the shipment value for intramexico.
                    item.freightClass = 50
                }
            })
        })
    }
    return newValues
}

export function requestQuote(quoteValues) {
    return async (dispatch, getState) => {
        try {
            await pastDateValidator(quoteValues, dispatch)
        } catch (error) {
            throw new SubmissionError(error)
        }
        const state = getState()
        const accessorialValues = get(state, "form.accessorials.values", {})

        let formDeliveryAccessorials = accessorialValues?.deliveryAccessorials
        if (typeof formDeliveryAccessorials === "string") {
            formDeliveryAccessorials = formDeliveryAccessorials.split(",")
        }
        const selectedResiAccessorials = quoteValues?.resiAccessorials
        const combinedDeliveryAccessorials =
            union(formDeliveryAccessorials, selectedResiAccessorials) ?? []

        quoteValues.deliveryAccessorials = combinedDeliveryAccessorials.map(
            it => {
                return { value: it }
            }
        )

        accessorialValues.deliveryAccessorials = combinedDeliveryAccessorials
        let values = {
            ...quoteValues,
            ...createAccessorialsPayload(accessorialValues),
        }

        values = intraMexicoFreightClassCheck(values)
        dispatch(mapFormToSearch(quoteValues, accessorialValues))

        dispatch(quoteLoad())
        const isUpdate =
            get(state, "quotes.active.isLoaded") && !quoteValues.isReturnMode
        const shipmentId = get(state, "identifiers.internalTrackingNumber")
        const url = isUpdate ? `/quotes/${shipmentId}` : "/quotes"
        let quotes

        dispatch(
            trackGAEvent(
                "Legacy Rates",
                "Rate Request Pre-Fire",
                `isUpdate: ${isUpdate}`
            )
        )
        try {
            const { data } = await goFetch(
                url,
                {
                    method: isUpdate ? "PUT" : "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: createPayload(values),
                },
                true
            )
            dispatch(
                trackGAEvent(
                    "Legacy Rates",
                    "Rate Request Success",
                    `isUpdate: ${isUpdate}`
                )
            )
            quotes = data
        } catch (err) {
            dispatch(trackGAEvent("Legacy Rates", "Rate Request Error", err))
            throw rateRequestError
        }
        if (!get(quotes, "result.rateQuotes")) {
            dispatch(trackGAEvent("Legacy Rates", "No Rate Results"))
            throw new SubmissionError({ _error: noRateResults })
        }

        dispatch(invalidateItemList())
        dispatch(loadQuoteResult(quotes))
    }
}

export function requestQuoteAdditionalServices(values) {
    return async (dispatch, getState) => {
        try {
            dispatch(quoteLoad())
            const state = getState()
            const shipmentId = get(state, "identifiers.internalTrackingNumber")
            const url = `/quotes/${shipmentId}`
            const { data: quotes } = await goFetch(
                url,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        ...createPayload(state.search),
                        ...createAccessorialsPayload(values),
                    },
                },
                true
            )
            if (!quotes.result || !quotes.result.rateQuotes)
                throw new Error(noRateResults)
            dispatch(loadQuoteResult(quotes, false))
            dispatch(updateQuoteResult(quotes, false))
        } catch (error) {
            dispatch(quoteLoadError(error))
        }
    }
}

const quoteListLoad = () => ({ type: QUOTE_LIST_LOAD })
const quoteListError = error => typedError(QUOTE_LIST_ERROR, error)
const quoteListResult = quotes => ({ type: QUOTE_LIST_RESULT, quotes })
const quoteListResultFilterApplied = quotes => ({
    type: QUOTE_LIST_RESULT_FILTER_APPLIED,
    quotes,
})

export function fetchQuoteListAndCount(
    targetSize,
    dashboardFilters,
    filterApplied,
    backfillReq
) {
    return async (dispatch, getState) => {
        try {
            const { pagination } = getState().quotes
            targetSize = targetSize ?? pagination.pageSize
            let pageNumber = Math.round(targetSize / pagination.pageSize)
            let params =
                targetSize > 0
                    ? { pageSize: pagination.pageSize, pageNumber }
                    : { pageSize: 0 }

            if (dashboardFilters) {
                const {
                    locationFilter,
                    carrierFilter,
                    archiveFilter,
                } = dashboardFilters

                if (locationFilter) {
                    params.cpg = Object.keys(locationFilter).filter(
                        key => !!locationFilter[key]
                    )
                }

                if (carrierFilter) {
                    params.scac = Object.keys(carrierFilter).filter(
                        key => !!carrierFilter[key]
                    )
                }

                if (archiveFilter?.viewArchivedQuotes) {
                    params.viewArchived = true
                }
            }

            const { data: quote, status } = await goFetch(
                "/quotes",
                {
                    method: "GET",
                    credentials: "same-origin",
                    params,
                    validErrorCodes: [404],
                },
                true
            )
            if (status === 404) return dispatch(quoteListResult({ list: [] }))
            if (backfillReq) {
                return dispatch({ type: BACKFILL_QUOTE_LIST, quotes: quote })
            }
            if (filterApplied) {
                return dispatch(quoteListResultFilterApplied(quote))
            }
            return dispatch(quoteListResult(quote))
        } catch (error) {
            return dispatch(quoteListError(error))
        }
    }
}

export function requestQuotes(targetSize, force) {
    return async (dispatch, getState) => {
        const quotesList = get(getState(), "quotes.list.items", {})
        const dashboardFilters = get(
            getState(),
            "form.dashboardFilters.values",
            {}
        )

        if (!force) {
            if (!shouldRequestQuotes(getState().quotes.list)) return null
            if (targetSize && targetSize <= Object.keys(quotesList).length)
                return null
        }

        dispatch(quoteListLoad())
        return dispatch(fetchQuoteListAndCount(targetSize, dashboardFilters))
    }
}

export async function resiAddressRequest(value) {
    const addressReply = await goFetch(
        "/fedex-shipments/address-validation",
        {
            method: "POST",
            credentials: "same-origin",
            data: value,
        },
        true
    )
    return addressReply?.data?.classification === "RESIDENTIAL"
}

export async function searchShipmentByProNumber(proNumber) {
    const params = {
        q: proNumber,
        exactMatchQuery: true,
        includeExternalShipments: true,
    }
    const { data } = await goFetch("/shipments", { params }, true)
    return data?.list
}

export function requestQuotesWithFilters(targetSize) {
    return async (dispatch, getState) => {
        const dashboardFilters = getState().dashboard?.dashboardFilters ?? {}

        dispatch(quoteListLoad())
        return dispatch(
            fetchQuoteListAndCount(targetSize, dashboardFilters, true)
        )
    }
}

const quoteSearchResult = (value, data = []) => ({
    type: QUOTE_SEARCH_RESULT,
    value,
    data,
})

function innerSearchQuotes(value) {
    return async dispatch => {
        try {
            const params = { q: value, pageSize: 5 }
            const { data, status } = await goFetch(
                "/quotes",
                { validErrorCodes: [404], params },
                true
            )
            if (status === 404) return dispatch(quoteSearchResult(value))
            return dispatch(quoteSearchResult(value, data.list))
        } catch (error) {
            return dispatch(errorMessage(error))
        }
    }
}

export function searchQuotes(value) {
    return async (dispatch, getState) => {
        if (!value || value.length < 3) return
        const result = getState().quotes.list.search[value]
        if (result) return
        const { searchInProgress } = getState().quotes.list
        clearTimeout(searchInProgress)
        const timeoutId = setTimeout(
            () => dispatch(innerSearchQuotes(value)),
            USER_INPUT_ENDED_DELAY
        )
        dispatch({ type: QUEUE_QUOTE_SEARCH, id: timeoutId })
    }
}

export function exportQuotesAsCsv() {
    return async dispatch => {
        try {
            const { data } = await goFetchV2(
                "/quotes/csv",
                {
                    method: "GET",
                    credentials: "same-origin",
                    responseType: "text",
                },
                true,
                null,
                "text/csv"
            )
            const blob = new Blob([data], {
                type: "text/csv;charset=utf-8",
            })
            FileSaver.saveAs(blob, "Quotes.csv")
            return blob
        } catch (error) {
            dispatch(errorMessage(error))
            throw error
        }
    }
}
