import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import AddCarrierDisplay from "./AddCarrierDisplay"
import { requestCarrierList } from "./requests"
import GlobalSpinner from "../common/GlobalSpinner"

const AddCarrier = ({ allCarriers, history, onboardingLocation }) => {
    const [addableCarriers, setAddableCarriers] = useState([])
    const [carrierListLoading, setCarrierListLoading] = useState(false)
    const [carrierListError, setCarrierListError] = useState(null)

    useEffect(() => {
        const fetchCarrierList = async () => {
            setCarrierListLoading(true)
            try {
                const rawAvailableCarriersToAdd = await requestCarrierList(
                    onboardingLocation?.cpgCode
                )
                const availableCarriersToAdd = rawAvailableCarriersToAdd.map(
                    carrierObj => {
                        return {
                            ...carrierObj,
                            carrierDetails: allCarriers
                                ? allCarriers[
                                      carrierObj?.capacityProviderIdentifiers[0]
                                          ?.value
                                  ]
                                : null,
                        }
                    }
                )
                setAddableCarriers(availableCarriersToAdd)
            } catch (error) {
                setCarrierListError(error?.response?.data)
            } finally {
                setCarrierListLoading(false)
            }
        }

        if (allCarriers) {
            fetchCarrierList()
        }
    }, [allCarriers])

    const onCarrierSelect = carrier => {
        history.push({
            pathname: `/carrier/add/${carrier?.capacityProviderIdentifiers?.[0]?.value}`,
            state: history.location.state,
        })
    }

    return carrierListLoading ? (
        <GlobalSpinner />
    ) : (
        <Grid container>
            {addableCarriers && (
                <AddCarrierDisplay
                    onCarrierSelect={onCarrierSelect}
                    carrierList={addableCarriers}
                />
            )}
        </Grid>
    )
}

AddCarrier.propTypes = {
    history: PropTypes.object.isRequired,
}

export default AddCarrier
