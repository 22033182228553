import React, { Component } from "react"
import { reduxForm } from "redux-form"
import { connect } from "react-redux"
import Drawer from "@material-ui/core/Drawer"
import { get } from "lodash"
import { Grid, Typography, Button } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import DashboardLocationFilter from "./locationFilter"
import DashboardCarrierFilter from "./carrierFilter"
import ArchiveFilter from "./ArchiveFilter"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Badge from "@material-ui/core/Badge"
import { FormattedMessage } from "react-intl"
import { trackGAEvent } from "../../../actions/user"

const GACategory = "Dashboard"

const styles = theme => ({
    mainContainer: {
        width: 400,
        height: "100%",
    },
    titleContainer: {
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        color: "white",
        height: 64,
    },
    filtersContainer: {
        height: "calc(100% - 110px)",
        overflowY: "scroll",
    },
    buttonContainer: {
        height: "46px",
        padding: "5px",
        borderTop: "Solid 2px #D4D4D4",
    },
    panelSummary: {
        "&:hover": {
            backgroundColor: "#D4D4D4",
        },
        width: "100%",
        paddingTop: 5,
        paddingTBottom: 5,
    },
    expansionPanelRoot: {
        width: "100%",
    },
    badgePadding: {
        paddingRight: "15px !important",
    },
})

const filterCategories = ["locationFilter", "carrierFilter", "archiveFilter"]

class FilterDrawer extends Component {
    clear() {
        const { resetFormField } = this.props

        filterCategories.forEach(category => {
            resetFormField(category, {})
        })
    }

    render() {
        const {
            open,
            classes,
            applyDashboardFilters,
            dashboardFilters,
            locations,
            closeFilterDrawer,
            carriers,
            formValues,
            fetchElementsOnFilterApply,
            trackGA,
        } = this.props
        const expansionPanelClasses = {
            root: classes.expansionPanelRoot,
        }
        const validCarrierIds = new Set(
            locations?.flatMap(
                loc => loc.carriers?.map(c => c.carrier) ?? []
            ) ?? []
        )
        const locationFilterCount = locations.filter(
            loc => get(formValues, "locationFilter", [])[loc.cpgCode]
        ).length
        const carrierFilterCount = Object.keys(
            get(formValues, "carrierFilter", [])
        ).filter(entry => get(formValues, "carrierFilter", [])[entry]).length
        const archiveFilterCount = Object.keys(
            get(formValues, "archiveFilter", [])
        ).filter(entry => get(formValues, "archiveFilter", [])[entry]).length

        return (
            <Drawer anchor="right" open={open} onClose={closeFilterDrawer}>
                <Grid
                    item
                    container
                    className={classes.mainContainer}
                    alignContent="flex-start"
                >
                    <Grid
                        item
                        container
                        className={classes.titleContainer}
                        justify="center"
                        alignItems="center"
                    >
                        <Typography variant="h6" color="inherit">
                            <FormattedMessage
                                id="dashboard.filters__filters"
                                defaultMessage="Filters"
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.filtersContainer}
                        alignContent="flex-start"
                    >
                        <Grid item container>
                            <ExpansionPanel
                                elevation={0}
                                classes={expansionPanelClasses}
                                onChange={(event, expanded) => {
                                    trackGA(
                                        GACategory,
                                        `Location Accordion ${
                                            expanded ? "Expanded" : "Collapsed"
                                        }`
                                    )
                                }}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="locationFilter "
                                    className={classes.panelSummary}
                                >
                                    <Typography
                                        variant="h6"
                                        color={
                                            locationFilterCount
                                                ? "primary"
                                                : "default"
                                        }
                                    >
                                        <FormattedMessage
                                            id="dashboard.filters__location"
                                            defaultMessage="Location"
                                        />
                                    </Typography>
                                    {locationFilterCount > 0 && (
                                        <Badge
                                            badgeContent={locationFilterCount}
                                            color="primary"
                                            className={classes.badgePadding}
                                        />
                                    )}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                    classes={expansionPanelClasses}
                                >
                                    <DashboardLocationFilter
                                        locations={locations}
                                        gaCategory={GACategory}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        <Grid item container>
                            <ExpansionPanel
                                elevation={0}
                                classes={expansionPanelClasses}
                                onChange={(event, expanded) => {
                                    trackGA(
                                        GACategory,
                                        `Carrier Accordion ${
                                            expanded ? "Expanded" : "Collapsed"
                                        }`
                                    )
                                }}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="carrierFilter"
                                    className={classes.panelSummary}
                                >
                                    <Typography
                                        variant="h6"
                                        color={
                                            carrierFilterCount
                                                ? "primary"
                                                : "default"
                                        }
                                    >
                                        <FormattedMessage
                                            id="dashboard.filters__carrier"
                                            defaultMessage="Carrier"
                                        />
                                    </Typography>
                                    {carrierFilterCount > 0 && (
                                        <Badge
                                            badgeContent={carrierFilterCount}
                                            color="primary"
                                            className={classes.badgePadding}
                                        />
                                    )}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                    classes={expansionPanelClasses}
                                >
                                    <DashboardCarrierFilter
                                        carriers={carriers}
                                        validCarrierIds={validCarrierIds}
                                        gaCategory={GACategory}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>

                        <Grid item container>
                            <ExpansionPanel
                                elevation={0}
                                classes={expansionPanelClasses}
                                onChange={(event, expanded) => {
                                    trackGA(
                                        GACategory,
                                        `Archived Accordion ${
                                            expanded ? "Expanded" : "Collapsed"
                                        }`
                                    )
                                }}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="archiveFilter"
                                    className={classes.panelSummary}
                                >
                                    <Typography
                                        variant="h6"
                                        color={
                                            archiveFilterCount
                                                ? "primary"
                                                : "default"
                                        }
                                    >
                                        <FormattedMessage
                                            id="dashboard.filters__archive"
                                            defaultMessage="Archived"
                                        />
                                    </Typography>
                                    {archiveFilterCount > 0 && (
                                        <Badge
                                            badgeContent={archiveFilterCount}
                                            color="primary"
                                            className={classes.badgePadding}
                                        />
                                    )}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                    classes={expansionPanelClasses}
                                >
                                    <ArchiveFilter gaCategory={GACategory} />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        justify="space-between"
                        className={classes.buttonContainer}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                this.clear()
                                trackGA(GACategory, "Filter Clear Button Click")
                                closeFilterDrawer()
                                applyDashboardFilters()
                                fetchElementsOnFilterApply()
                            }}
                        >
                            <FormattedMessage
                                id="dashboard.filters__clear"
                                defaultMessage="Clear"
                            />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                closeFilterDrawer()
                                trackGA(GACategory, "Filter Apply Button Click")
                                applyDashboardFilters()
                                fetchElementsOnFilterApply()
                            }}
                        >
                            <FormattedMessage
                                id="dashboard.filters__apply"
                                defaultMessage="Apply"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Drawer>
        )
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
})

export default withStyles(styles)(
    connect(
        state => ({
            initialValues: state.dashboard.dashboardFilters, //
        }),
        mapDispatchToProps
    )(
        reduxForm({
            form: "dashboardFilters", // a unique identifier for this form
            enableReinitialize: true,
        })(FilterDrawer)
    )
)
